import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Card } from '@stripe/stripe-js';
import { LS } from '../../../common/LocalStorage';
import { AuthStore } from '../../../store/AuthStore';
import {
  DBOrderVO,
  DBShopifyOrderVO,
  LinkedProduct,
  LinkedShop,
  MyProductItemVO,
  PaymentCardInfoVO,
  ProductType,
  ShopifyActionType,
  ShopifyFulfillmentDetailVO,
} from '../../../TypeDeclare';
import {
  parseMyProduct,
  parseShopifyProduct,
} from '../../product_list/services/productService';
import {
  bindPlatformShop,
  deleteStripeCard,
  getOrders,
  getPaymentInfoList,
  getProducts,
  getShopifyLink,
  getShopifyOrderDetail,
  getShopifyOrders,
  getShopifyProducts,
  getStripeCS,
  runStripePayment,
  updateShopifyProduct,
} from '../services/dashboardService';
import { parseDBShopifyOrderVO } from '../../order_list/services/orderListService';

export class DashboardStore extends LS {
  @observable orders: Array<DBOrderVO> = [];
  @observable products: Array<MyProductItemVO> = [];
  @observable loadingProduct: boolean = false;
  @observable linkedShops: Array<LinkedShop> = [];
  @observable _paymentsCards: Array<PaymentCardInfoVO> = [];
  @observable currentPage: number = 1;
  @observable pageCount: number = 1;
  linkedProducts: Array<LinkedProduct> = [];
  stripeCS: string = '';

  authStore?: AuthStore;

  constructor() {
    super();
    makeObservable(this);
  }

  setAuth(_auth: AuthStore) {
    this.authStore = _auth;
  }
  @computed
  get paymentsCards(): Array<PaymentCardInfoVO> {
    const addNewCard: PaymentCardInfoVO = {
      id: 'new',
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cardType: '',
      customerId: '',
      newCard: true,
    };
    return [addNewCard, ...this._paymentsCards];
  }
  get rawPaymentCards(): Array<PaymentCardInfoVO> {
    return this._paymentsCards;
  }
  @action
  setLinkedShops(rawLinkedShops: Array<any>) {
    runInAction(() => {
      this.linkedShops = rawLinkedShops.map((item, index) => {
        return {
          id: (index + 1).toString(),
          url: item['store_url'],
          ctToken: item['customtee_token'],
          platform: 'shopify',
        };
      });
    });
  }

  @action
  setOrders(data: Array<DBOrderVO>) {
    runInAction(() => {
      this.orders = data;
    });
  }
  async getMyOrders(token: string): Promise<Array<DBOrderVO>> {
    const result = await getOrders(token);
    const orders: Array<DBOrderVO> = result.data.map((o: any) => {
      const order: DBOrderVO = {
        id: String(o['order_num']),
        customerName: String(o['customer_name']),
        updateTime: String(o['last_updated_time']),
        orderType: String(o['order_type']),
        paidTime: String(o['paid_time']),
        quantity: Number(o['quantity']),
        status: String(o['status_comment']),
        totalAmount: Number(o['total_amount']),
      };
      return order;
    });

    return orders;
  }

  async getShopifyOrders(
    token: string,
    shopToken: string
  ): Promise<Array<DBShopifyOrderVO>> {
    const result = await getShopifyOrders(token, shopToken);
    const orders: Array<DBShopifyOrderVO> = result.data.map((o: any) => {
      return parseDBShopifyOrderVO(o);
    });

    return orders;
  }
  async getShopifyOrderDetail(
    token: string,
    shopToken: string,
    orderId: string
  ): Promise<Array<ShopifyFulfillmentDetailVO>> {
    const result = await getShopifyOrderDetail(token, shopToken, orderId);
    const orders: Array<ShopifyFulfillmentDetailVO> = result.data.map(
      (d: any) => {
        return d as ShopifyFulfillmentDetailVO;
      }
    );
    return orders;
  }

  async getMyProducts(token: string, type: ProductType, page: number) {
    runInAction(() => {
      this.products = [];
      this.loadingProduct = true;
      this.currentPage = page;
    });
    const result = await getProducts(token, type, page);

    const products: Array<MyProductItemVO> = result.results.map((r: any) => {
      return parseMyProduct(r, [{ image: r['cover_image'] }]);
    });
    runInAction(() => {
      this.pageCount = Math.ceil(Number(result.count) / 30);

      this.products = products;
      this.loadingProduct = false;
    });
  }

  async getShopifyProducts(token: string, shopToken: string, page: number) {
    runInAction(() => {
      this.products = [];
      this.loadingProduct = true;
      this.currentPage = page;
    });
    const result = await getShopifyProducts(token, shopToken, page);
    if (result.success) {
      const products: Array<MyProductItemVO> = result.data.map((r: any) => {
        return parseShopifyProduct(r);
      });
      runInAction(() => {
        //TODO: this api should return count
        this.pageCount = Math.ceil(Number(result.total_count) / 30);
        console.log('getShopifyProducts', products);
        this.products = products;
        this.loadingProduct = false;
      });
    } else {
      runInAction(() => {
        this.loadingProduct = false;
      });
    }
  }
  async updateShopifyProduct(
    token: string,
    shopToken: string,
    productId: string,
    action: ShopifyActionType
  ): Promise<boolean> {
    const result = await updateShopifyProduct(
      token,
      shopToken,
      productId,
      action
    );
    console.log(result);
    return result.data.success;
  }

  @action
  updateSingleProduct(newProduct: MyProductItemVO) {
    const index = this.products.findIndex((p) => p.id === newProduct.id);
    this.products = [
      ...this.products.slice(0, index),
      newProduct,
      ...this.products.slice(index + 1),
    ];
  }
  @action
  removeProductFromStore(id: string) {
    this.products = this.products.filter((p) => p.id !== id);
  }
  @action
  async bindShop(token: string, key: string): Promise<boolean> {
    const result = await bindPlatformShop(token, key);
    if (result.success) {
    }
    return Boolean(result.success);
  }

  async getShopifyProductLink(
    productId: string,
    shopifyToken: string
  ): Promise<string> {
    const product = this.linkedProducts.find(
      (lp) => lp.ctProductId === productId
    );
    if (product) {
      console.log('hit', product.url);
      return product.url;
    }
    const result = await getShopifyLink(
      this.authStore?.token!,
      productId,
      shopifyToken
    );
    const shop = this.linkedShops.find((s) => s.ctToken === shopifyToken);
    if (shop) {
      this.linkedProducts.push({
        shop,
        ctProductId: productId,
        url: result,
      });
    }

    return result;
  }

  async getPaymentCards(token: string): Promise<void> {
    const cards = await getPaymentInfoList(token);
    runInAction(() => {
      this._paymentsCards = cards;
    });
  }

  async getStripeCS(): Promise<string> {
    if (this.stripeCS !== '') {
      return this.stripeCS;
    }
    const result = await getStripeCS(this.authStore?.token!);
    this.stripeCS = result['client_secret'];
    return this.stripeCS;
  }
  async saveStripeToken(st: string, card: Card): Promise<boolean> {
    const result = await runStripePayment(this.authStore?.token!, card, st);
    return result;
  }

  async payByStripe(customerId: string, orderId: string): Promise<boolean> {
    console.log(customerId, orderId);
    const result = await runStripePayment(
      this.authStore?.token!,
      undefined,
      undefined,
      orderId,
      customerId
    );
    return result;
  }
  async deleteStripeCard(id: string): Promise<boolean> {
    const result = await deleteStripeCard(this.authStore?.token!, id);
    return result;
  }
}
