import { Box } from '@fower/react'
import { styled } from '@fower/styled'
import { Menu, MenuItem, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../store/useAuthStore'
import ContactUS from '../../common/ContactUS'
import { observer } from 'mobx-react-lite'
import { LoginMode } from '../../TypeDeclare'
import BlobMenu from '../../common/Blob'

export type HomeHeaderProps = {
    fullWidth: boolean
    page: 'home' | 'dashboard' | 'catalog'
    reloadAfterLogout?: boolean
    onLogin?: (mode: LoginMode) => void
    onShowMenu?: () => void
    onLogout?: () => void
}
const HomeHeader: FC<HomeHeaderProps> = observer(
    ({
        fullWidth,
        page,
        onShowMenu,
        onLogout,
        onLogin,
        reloadAfterLogout = true,
    }) => {
        const authStore = useAuthStore()
        const StyledImage = styled('img')
        const navigator = useNavigate()
        const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
        const open = Boolean(anchorEl)
        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget)
            onShowMenu && onShowMenu()
        }
        const handleClose = () => {
            setAnchorEl(null)
        }
        const toCatalog = () => {
            handleClose()
            navigator('/catalog/all')
        }
        const toDashboard = () => {
            handleClose()
            navigator('/dashboard')
        }
        const toHome = () => {
            handleClose()
            navigator('/')
        }
        return (
            <Box
                w="100%"
                w--md={fullWidth ? '100%' : '80%'}
                toCenter
                toCenterY
                h="72px"
                toBetween--lg
                borderBottom={'1px solid rgba(110, 143, 160, 0.5)'}
                borderBottom--lg={'0px'}
                relative
                pl3={fullWidth}
                pr3={fullWidth}
            >
                <Box left2 top5 absolute hidden--lg onClick={handleClick}>
                    <MenuIcon color="primary" fontSize="large" />
                </Box>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={toCatalog}>Catalog</MenuItem>
                    <MenuItem onClick={() => {
                        global.location.href = global.location.origin + '/aboutUs'

                    }}>Contact Us</MenuItem>
                </Menu>
                {/* Icon */}
                <Box toCenterY onClick={toHome} borderRightBlack>
                    <StyledImage src="/icons/logo_macaw.png" alt="logo" w10 h10 />
                    <StyledImage
                        src="/icons/logo_text.png"
                        alt="logo-text"
                        w="180px"
                        h="24px"
                        ml2
                        hidden
                        display--lg="block"
                    />
                </Box>
                <Box toCenterY spaceX4 row hidden display--lg="flex">
                    {page !== 'catalog' && (
                        <Box cursorPointer onClick={toCatalog}>
                            <Typography variant="h3" color={'#4384D6'}>
                                Catalog
                            </Typography>
                        </Box>
                    )}

                    {authStore.isLogin && (page === 'home' || page === 'catalog') && (
                        <Box cursorPointer onClick={toDashboard}>
                            <Typography variant="h3" color={'#4384D6'}>
                                Dashboard
                            </Typography>
                        </Box>
                    )}
                    <ContactUS
                        onAction={() => {
                            handleClose()
                        }}
                    />

                    <BlobMenu
                        onAction={() => {
                            handleClose()
                        }}
                    />

                    {authStore.isLogin && (
                        <Box
                            cursorPointer
                            onClick={() => {
                                authStore.logout(reloadAfterLogout)
                                onLogout && onLogout()
                            }}
                        >
                            <Typography variant="h3" color={'#4384D6'}>
                                Sign Out
                            </Typography>
                        </Box>
                    )}
                    {!authStore.isLogin && (
                        <>
                            <Box
                                cursorPointer
                                onClick={() => {
                                    onLogin && onLogin('login')
                                }}
                            >
                                <Typography variant="h3" color={'#4384D6'}>
                                    Log In
                                </Typography>
                            </Box>
                            <Box
                                cursorPointer
                                onClick={() => {
                                    onLogin && onLogin('signup')
                                }}
                            >
                                <Typography variant="h3" color={'#4384D6'}>
                                    Sign Up
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        )
    },
)

export default HomeHeader
