import { Box } from '@fower/react'
import {
    Grid,
    ImageList,
    ImageListItem,
    Skeleton,
    Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styles from '../../../product_list/ProductList.module.css'
import { OriginalProductItemVO } from '../../../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import { styled } from '@fower/styled'
type ItemViewProps = {
    data: OriginalProductItemVO
    onClick: (item: OriginalProductItemVO) => void
}
const StyledImage = styled('img')
const ItemView: FC<ItemViewProps> = ({ data, onClick }) => {
    const { name, coverImages, descShorten, pairPrice } = data

    return (
        <Box
            minW="280px"
            h="480px"
            border="solid 1px rgba(0,0,0,0.2)"
            column
            overflowHidden
            bg={'#F4F6F7'}
            relative
            cursorPointer
            onClick={() => {
                onClick(data)
            }}
        >
            <StyledImage
                src={coverImages[0]}
                alt={name}
                className={styles.itemImage}
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '296px',
                }}
            />
            <Typography
                variant="h4"
                sx={{
                    m: 2,
                }}
            >
                {name}
            </Typography>
            <Box textXS ml4 mr4 mb2 gray600 h12 className={styles.multiLine}>
                {descShorten}
            </Box>
            <Box ml4 mt7 color="#527282" fontBold>
                ${pairPrice}
            </Box>
        </Box>
    )
}

export type HistoryProductsProps = {
    list: OriginalProductItemVO[]
    onSelect: (item: OriginalProductItemVO) => void
}
const HistoryProducts: FC<HistoryProductsProps> = observer(({ list, onSelect }) => {
    const getCV = () => {
        if (global.innerWidth > 1700) return 3
        if (global.innerWidth > 1400) return 2
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    return (
        <ImageList
            sx={{
                width: '100%',
                height: '100%',
                marginTop: '0px',
                padding: '5px',
            }}
            variant="quilted"
            cols={columns}
            rowHeight={500}
        >
            {list.map((item, index) => {
                if (item.id === '-1') {
                    return (
                        <ImageListItem key={index.toString()}>
                            <Grid key={index.toString()} height={480} width={'100%'}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={296}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={184}
                                    sx={{
                                        bgcolor: 'grey.200',
                                    }}
                                />
                            </Grid>
                        </ImageListItem>
                    )
                }
                return (
                    <ImageListItem key={item.id}>
                        <ItemView
                            data={item}
                            onClick={(data) => {
                                onSelect(data)
                            }}
                        />
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
})
export default HistoryProducts