import { Box } from '@fower/react'
import { FC } from 'react'
import styles from './LandingSection.module.css'
import { FeaturedProductListItem, ProductItemVO } from '../../TypeDeclare'
import ItemList from '../product_list/ItemList'

export type FeaturedListProps = {
    feature: FeaturedProductListItem
    onSelect: (item: ProductItemVO) => void
}
const FeaturedList: FC<FeaturedListProps> = ({ feature, onSelect }) => {
    return (
        <>
            <Box
                className={styles.developerChoiceTitle}
                mb10
                hidden
                display--lg="flex"
            >
                {feature.name}
            </Box>
            <Box
                className={styles.developerChoiceTitleSmall}
                mb8
                hidden--lg
                textCenter
            >
                {feature.name}
            </Box>
            {/* <Box bg="#4384D6" w11 h1 mt2 mb12></Box> */}
            <ItemList list={feature.products} onSelect={onSelect} />
        </>
    )
}
export default FeaturedList
