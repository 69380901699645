import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@fower/react";
import { Button, Divider, Pagination, Skeleton } from "@mui/material";
import { doGet } from "../../../common/commonService";
import { styled } from '@fower/styled'
import { useNavigate } from "react-router-dom";
import ItemList from "../../product_list/ItemList";
import { ProductItemVO } from "../../../TypeDeclare";
import { useProductStore } from "../../product_list/store/useProdustListStore";

export type NewDashBoardHomeProps = {
    onToConnect: () => void
    onToCreate: () => void
}

const StyledIframe = styled('iframe')

const NewDashboardHome: FC<NewDashBoardHomeProps> = ({ onToConnect, onToCreate }) => {

    const navigator = useNavigate()
    const store = useProductStore()

    const [blog, setBlog] = useState({
        cover_image: '',
        title: '',
        id: '',
        seo_url: ''
    })

    const skeletonData = [
        '-1',
        '-1',
        '-1'
    ].map((id, i) => {
        return {
            id: id,
            name: '',
            category: { id, parentName: '', category: 0, tag: 0 },
            type: '',
            images: [],
            descShorten: '',
            desc: '',
            sizeRange: '',
            price: '',
            daysInProduction: 0,
            sizeTableImage: '',
            colorId: '',
            shippingFee: '',
            isFullPrint: false,
            productId: '',
            shippingMethods: [],
            coverImages: [],
            weight: 0,
            volume: {
                width: 0,
                height: 0,
                length: 0,
            },
            readyToShip: false
        };
    });


    const [products, setProducts] = useState<Array<ProductItemVO>>(skeletonData)
    const productList = useRef<Array<ProductItemVO>>([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        // setLoading(true)
        store.reloadByCategory(store.targetCategory, 1).then((success) => {
            // setLoading(false)
            productList.current = store.products.slice(0, 15)
            setProducts(productList.current.slice(0, 3))
        })

    }, [store, store.readyToShip])

    async function getBlogList() {
        setLoading(true)
        const res = await doGet(`https://api.customtee.co/api/customteeBlogList/`, { page: 1 }, undefined)

        if (res.success && res.data.length > 0) {
            setBlog({
                title: res.data[0].title,
                cover_image: res.data[0].cover_image,
                id: res.data[0].id,
                seo_url: res.data[0].seo_url,
            })
            setLoading(false)
        }

    }

    useEffect(() => {
        getBlogList()
    }, [])


    return (
        <Box
            w-100p

            column
            boxSizing="border-box"
            p8
        >

            <h1 style={{
                fontFamily: 'Segoe UI, Segoe UI',
                fontSize: '50px',
                color: '#518DD9',
                fontWeight: 'bold',
                lineHeight: '52px'
            }}>Welcome Custom Tee!</h1>

            <Box
                w="100%"
                flex
                alignItems="center"
                justifyContent="space-between"
                flexWrap
                css={{
                    background: '#EFEFEF',
                    borderRadius: '10px',
                    padding: '20px 40px'
                }}
            >
                <Box
                    w="70%"
                    css={{
                        fontFamily: 'Segoe UI, Segoe UI',
                        fontSize: '20px',
                        color: '#363636',
                        fontWeight: 'bold',
                        lineHeight: '30px'
                    }}
                >
                    Link your Shopify store, design products, and start earning with custom tees to unlock a 20% rebate.
                </Box>
                <Button
                    variant="contained"
                    style={{
                        borderRadius: '20px',
                    }}
                    onClick={onToConnect}
                >Link my store</Button>
            </Box>

            <Box
                w="100%"
                flex
                flexWrap
                justifyContent="space-between"
                css={{
                    marginTop: '50px',
                }}
            >
                <Box
                    flex
                    column
                    toCenter
                    boxSizing="border-box"
                    w={['100%', '100%', '100%', '30%', '30%']}

                    css={{
                        background: '#518DD9',
                        borderRadius: '10px',
                        padding: '20px'
                    }}
                >
                    <Box

                        css={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            color: '#ffffff',
                            fontWeight: '600',
                            fontSize: '40px',
                            lineHeight: '42px'
                        }}
                    >Start creating your first product.</Box>

                    <Button
                        style={{
                            color: '#518DD9',
                            background: '#ffffff',
                            borderRadius: '30px',
                            width: '100%',
                            padding: '10px 0',
                            fontSize: '30px',
                            marginTop: '70px'
                        }}
                        variant="contained"
                        onClick={onToCreate}
                    >Start creating</Button>
                </Box>

                {/* TODO */}
                <Box
                    w={['100%', '100%', '100%', '69%', '69%']}

                    relative
                    css={{
                        background: '#EFEFEF',
                        borderRadius: '10px',
                        paddingBottom: '50px'
                    }}
                >

                    <ItemList list={products} onSelect={(item) => {

                        const { name, id } = item
                        const newName = name.toLocaleLowerCase().split(' ').join('-')
                        // navigator(`/detail?productId=${item.id}&data=${selectedItem ? JSON.stringify(selectedItem) : ''}`)
                        navigator(`/products/${id}/${newName}`)
                    }} />

                    <Pagination
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                        count={5}
                        page={page}
                        boundaryCount={2}
                        color="primary"
                        onChange={(_, page) => {

                            setPage(page)
                            setProducts(productList.current.slice((page - 1) * 3, page * 3))
                        }} />
                </Box>
            </Box>

            <Box
                w="100%"
                css={{
                    background: '#EFEFEF',
                    borderRadius: '18px 18px 0 0',
                    marginTop: '50px',
                }}
            >
                {/* <Box
                    w="100%"
                    h="80px"
                    flex
                    alignItems="center"
                    css={{
                        background: '#518DD9',
                        borderRadius: '18px 18px 0 0',
                        fontFamily: 'Segoe UI, Segoe UI',
                        fontSize: '22px',
                        fontWeight: '600',
                        color: '#ffffff',
                        padding: '0 35px'
                    }}
                >
                    Expand Your Horizons😀
                </Box>

                <Box
                    w="100%"
                    css={{
                        padding: '35px'
                    }}
                >
                    <Box
                        css={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            color: '#000000',
                            fontSize: '22px',
                            fontWeight: '600',
                            lineHeight: '24px'
                        }}
                    >
                        Boost Your Shopify Store's Success:Enjoy more member benefits.✈
                    </Box>

                    <Button
                        style={{
                            borderRadius: '20px',
                            marginTop: '30px'
                        }}
                        variant="contained">View plans</Button>
                </Box> */}


                {/* <Box h="40px" bgWhite></Box> */}
                <Box
                    w="100%"
                    h="600px"
                    flex
                    flexWrap
                    bgWhite
                    justifyContent="space-between"

                >

                    <Box
                        w={['100%', '100%', '100%', '48%', '48%']}
                        // h="100%"
                        css={{
                            background: '#EFEFEF',
                            borderRadius: '10px',
                            padding: '30px',
                            marginTop: '10px'
                        }}
                    >

                        <Box
                            flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                flex
                                alignItems="center"
                            >
                                <img
                                    src="/icons/icon-text.png"
                                    style={{
                                        width: '39px',
                                        height: '39px'
                                    }} />

                                <span
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        marginLeft: '10px'
                                    }}
                                >Custom Tee Blog</span>
                            </Box>

                            <Button
                                onClick={() => {
                                    navigator('/blog')
                                }}
                                variant="text"
                            >See more</Button>
                        </Box>

                        <Divider style={{ padding: '10px 0' }} />

                        {
                            loading ?
                                <>
                                    <Skeleton variant="rectangular" width={'100%'} height={330} />
                                    <Skeleton variant="rectangular" width={'100%'} height={40} style={{ marginTop: '10px' }} />
                                </>
                                : <>
                                    <img
                                        src={blog.cover_image}
                                        style={{
                                            width: '100%',
                                            height: '330px',
                                            objectFit: 'cover',
                                            marginTop: '30px'
                                        }}
                                        alt="blog" />

                                    <p
                                        style={{
                                            fontFamily: 'Segoe UI, Segoe UI',
                                            color: '#636363',
                                            fontSize: '18px',
                                            lineHeight: '20px'
                                        }}
                                    >{blog.title}</p>
                                </>
                        }


                        <Box
                            w="100%"
                            flex
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    borderRadius: '20px'
                                }}
                                onClick={() => {
                                    navigator(`/blog/${blog.id}/${blog.seo_url}`)
                                }}
                            >Read article</Button>
                        </Box>
                    </Box>

                    <Box
                        w={['100%', '100%', '100%', '48%', '48%']}
                        // h="100%"
                        css={{
                            background: '#EFEFEF',
                            borderRadius: '10px',
                            padding: '30px',
                            marginTop: '10px'
                        }}
                    >
                        <Box
                            flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                flex
                                alignItems="center"
                            >
                                <img
                                    src="/icons/icon-video.png"
                                    style={{
                                        width: '39px',
                                        height: '39px'
                                    }} />

                                <span
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        marginLeft: '10px'
                                    }}
                                >Tutorial: Connecting to Your Shopify Store</span>
                            </Box>

                            {/* <Button variant="text">See more</Button> */}
                        </Box>

                        <Divider style={{ padding: '10px 0' }} />

                        <StyledIframe
                            style={{
                                marginTop: '30px'
                            }}
                            w="100%"
                            h="360px"
                            src="https://www.youtube.com/embed/lhA5b97sK-8"
                            title="Custom Tee Integration for Shopify"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></StyledIframe>
                    </Box>

                </Box>
            </Box>

        </Box >
    )
}

export default NewDashboardHome;