import { Box } from '@fower/react'
import { styled } from '@fower/styled'
import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../store/useAuthStore'
import styles from './LandingSection.module.css'

export type LadingSectionProps = {
    onLogin: () => void
}
const LandingSection: FC<LadingSectionProps> = observer(({ onLogin }) => {
    const authStore = useAuthStore()
    const navigator = useNavigate()
    const StyledImage = styled('img', {
        objectFit: 'cover',
    })
    const toProducts = () => {
        navigator('/catalog/all')
    }
    return (
        <Box
            w="100%"
            w--md="80%"
            toBetween--md
            toCenter
            row--lg
            column
            space3
            pt6

        >
            <Box w="100%" w--xl="50%" column toCenter toLeft--lg spaceY2 pt2 pb2>
                <Box className={styles.landingSection} hidden display--lg="flex">
                    The Most Cost-Effective Print-On-Demand Solution for Online Sellers
                </Box>
                <Box className={styles.landingSectionSmall} p6 hidden--lg textCenter>
                    The Most Cost-Effective Print-On-Demand Solution for Online Sellers
                </Box>
                <Box row p3 mr5 ml5 m0--lg p0--lg>
                    <StyledImage
                        src="/icons/icon_light.png"
                        alt="icon_light"
                        w="36px"
                        h="37px"
                    />
                    <Box ml4 className={styles.landingSubtitle}>
                        Enjoy premium quality with fast shipping and fully customizable solutions
                    </Box>
                </Box>
                <Box hidden display--lg="flex" spaceX2>
                    <Button
                        variant="contained"
                        sx={{
                            mt: '72px',
                            width: '264px',
                            height: '48px',
                            background: '#4384D6'
                        }}
                        onClick={toProducts}
                    >
                        Start designing
                    </Button>

                </Box>
            </Box>
            <StyledImage
                src="/images/landing.png"
                alt="landing"
                w="100%"
                w--xl="50%"
                maxW="450px"
                pl8
                pr6
            />
            <Box hidden--lg mt2 mb3 column flex spaceY2>
                <Button
                    variant="contained"
                    sx={{
                        width: '264px',
                        height: '48px',
                        background: '#4384D6'
                    }}
                    onClick={toProducts}
                >
                    Start designing
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        width: '264px',
                        height: '48px',
                        background: '#4384D6',
                        color: '#fff'
                    }}
                    onClick={onLogin}
                >
                    {authStore.isLogin ? 'To Dashboard' : 'Login'}
                </Button>
            </Box>
        </Box>
    )
})

export default LandingSection
