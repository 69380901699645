import { OriginalProductStore } from './OriginalProductStore';

export type CheckResult = {
  passed: boolean;
  reason?: string;
};
export class OPConditionChecker {
  store: OriginalProductStore;
  constructor(store: OriginalProductStore) {
    this.store = store;
  }
  get categorySelected(): CheckResult {
    return {
      passed: Boolean(this.store.selectedChildCategory),
      reason: 'No category',
    };
  }

  get productName(): CheckResult {
    return {
      passed: Boolean(this.store.productName !== ''),
      reason: 'No product name',
    };
  }

  get pairPrice(): CheckResult {
    return {
      passed: Boolean(this.store.pairPrice !== 0),
      reason: 'Pair Price can not be 0',
    };
  }

  get description(): CheckResult {
    return {
      passed: Boolean(this.store.description !== ''),
      reason: 'No description',
    };
  }

  get colors(): CheckResult {
    return {
      passed: Boolean(this.store.colors.length),
      reason: 'No colors selected',
    };
  }

  get sizes(): CheckResult {
    return {
      passed: Boolean(this.store.sizes.length),
      reason: 'No sizes selected',
    };
  }

  get coverImage(): CheckResult {
    return {
      passed: Boolean(this.store.coverImages.length > 0),
      reason: 'No coverImage uploaded',
    };
  }

  get editableImages(): CheckResult {
    return {
      passed: Boolean(this.store.editableImages.length !== 0),
      reason: 'No editableImages uploaded',
    };
  }

  get maxMatrixCount(): CheckResult {
    return {
      passed: Boolean(
        this.store.sizes.length * this.store.colors.length <= 100
      ),
      reason: 'Can not create more than 100 variants of sku',
    };
  }

  get sizeRange(): CheckResult {
    return {
      passed: Boolean(this.store.sizeRange !== ''),
      reason: 'Please set the size range',
    };
  }

  get weight(): CheckResult {
    return {
      passed: Boolean(this.store.weight > 0),
      reason: 'Weight can not be 0 or less',
    };
  }

  get volume(): CheckResult {
    return {
      passed: Boolean(
        this.store.volume.width > 0 &&
          this.store.volume.height > 0 &&
          this.store.volume.length > 0
      ),
      reason: 'Please input meaningful volume value',
    };
  }

  get hasError(): boolean {
    return this.currentError !== undefined;
  }
  get currentError(): CheckResult | undefined {
    if (!this.categorySelected.passed) {
      return this.categorySelected;
    }
    if (!this.productName.passed) {
      return this.productName;
    }
    if (!this.pairPrice.passed) {
      return this.pairPrice;
    }
    if (!this.sizeRange.passed) {
      return this.sizeRange;
    }

    if (!this.description.passed) {
      return this.description;
    }
    if (!this.colors.passed) {
      return this.colors;
    }
    if (!this.sizes.passed) {
      return this.sizes;
    }
    if (!this.coverImage.passed) {
      return this.coverImage;
    }
    if (!this.editableImages.passed) {
      return this.editableImages;
    }
    if (!this.maxMatrixCount.passed) {
      return this.maxMatrixCount;
    }
    return undefined;
  }

  get sizeNColorError(): CheckResult | undefined {
    if(!this.weight.passed){
      return this.weight
    }
    if(!this.volume.passed){
      return this.volume
    }
    if (!this.maxMatrixCount.passed) {
      return this.maxMatrixCount;
    }
    if (!this.productName.passed) {
      return this.productName;
    }
    if (!this.pairPrice.passed) {
      return this.pairPrice;
    }
    if (!this.description.passed) {
      return this.description;
    }
    if (!this.colors.passed) {
      return this.colors;
    }
    if (!this.sizes.passed) {
      return this.sizes;
    }
    if (!this.sizeRange.passed) {
      return this.sizeRange;
    }

    return undefined;
  }
}
