import { styled } from '@fower/styled';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const IconButton: FC<{
    startIcon: any;
    onClick?: () => void;
    disabled?: boolean;
    size?: 'large' | 'small' | 'medium';
}> = observer((props) => {
    const size = props.size && 'medium';
    const FoweredButton = styled(Button);
    return (
        <>
            <FoweredButton
                disabled={props.disabled}
                onClick={props.onClick}
                variant='outlined'
                startIcon={props.startIcon}
                fullWidth
                m1
                // display={['none', 'none', 'none', 'inline-flex', 'inline-flex']}
                size={size}
                sx={{
                    color: '#4384D6'
                }}
            // sx={{
            //   display: ['none', 'none', 'none', 'inline-flex', 'inline-flex'],
            // }}
            >
                {props.children}
            </FoweredButton>
            {/* <Box circle5 bgBlack display={['none', 'none', 'none', 'inline-flex','inline-flex']}></Box> */}

            {/* display={['inline-flex', 'inline-flex', 'inline-flex', 'none', 'none']} */}
        </>
    );
});

export default IconButton;
