
import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import LoginModal from '../../common/LoginModal'
import { LoginMode } from '../../TypeDeclare'
// import DeveloperChoiceProducts from './DeveloperChoiceProducts'
import HomeFooter from './Footer'
import HomeHeader from './Header'
import KeyPoints from './KeyPoints'
import LandingSection from './LandingSection'
import IntroVideo from './IntroVideo'
import PopupGuide from './PopupGuide'
import { useAuthStore } from '../../store/useAuthStore'
import { LiveChatWidget } from '@livechat/widget-react'
import FeaturedProducts from './FeaturedProducts'
import { Helmet } from 'react-helmet'
import CloseIcon from '@mui/icons-material/Close';

const Home: FC = observer(() => {
    const [showPopupAD, setShowPopupAD] = useState<boolean>(false)
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    const { isLogin } = useAuthStore()
    useEffect(() => {
        const saved = localStorage.getItem('ct-ad-showed')

        if (!saved && !isLogin) {
            setShowPopupAD(true)
        }
    }, [isLogin])
    return (
        <div>
            <Helmet>
                <title>Custom Tee Co. - Print on Demand Services</title>
                {/* Meta Description: */}
                <meta name="description" content="Custom Tee Co. offers leading print-on-demand services for ecommerce businesses and individuals, providing custom t-shirts, hats, mugs, bags and more. Sign up for free and start your custom product business today." />

                {/* Meta Keywords: */}
                <meta name="keywords" content="print on demand, custom t-shirts, ecommerce solutions, custom products, personalized items, custom printing, dropshipping, custom apparel, personalized gifts, print on demand services" />

                {/* Open Graph Tags (for social media): */}
                <meta property="og:title" content="Custom Tee Co. - Print on Demand Services" />
                <meta property="og:description" content="Custom Tee Co. offers leading print-on-demand services for ecommerce businesses and individuals, providing custom t-shirts, hats, mugs, bags, hoodies and more. Sign up for free and start your custom product business today." />
                <meta property="og:image" content="http://www.customtee.co/path/to/your/image.jpg" />
                <meta property="og:url" content="http://www.customtee.co" />
                <meta property="og:type" content="website" />

            </Helmet>
            <Box w="100%" toCenterX column>
                <HomeHeader
                    fullWidth={false}
                    page={'home'}
                    onLogin={(mode: LoginMode) => {
                        setPreMode(mode)
                        setShowLogin(true)
                    }}
                    onShowMenu={() => { }}
                />
                <LandingSection
                    onLogin={() => {
                        setShowLogin(true)
                    }}
                />
                <KeyPoints />

                <Box w="100%" column toCenter pt20>
                    <Box
                        hidden display--md="flex"
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '34px',
                            color: '#000000',
                            fontWeight: 'bold',
                            lineHeight: '54px'
                        }}
                        textCenter
                    >Custom Tee Shopify App</Box>
                    <Box
                        hidden--md
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '20px',
                            color: '#000000',
                            fontWeight: 'bold',
                            lineHeight: '54px'
                        }}
                        textCenter
                    >Custom Tee Shopify App</Box>
                    <Box
                        w="80%"
                        textCenter
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '20px',
                            color: '#7B7B7B',
                            fontWeight: '600',
                            marginTop: '20px',
                            lineHeight: '25px'
                        }}
                    >Simply integrate your Shopify store with Custom Tee's fulfillment solution in just a few clicks. We've launched our Shopify app!</Box>
                </Box>

                <IntroVideo />

                {/* <Box
                    h="350px"
                    w="80%"
                    css={{
                        background: '#D1E5FF',
                        boxShadow: '6px 3px 6px 1px rgba(0,0,0,0.16)',
                        borderRadius: '10px',
                    }}
                    relative
                >
                    <CloseIcon
                        style={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            cursor: 'pointer'
                        }}
                         />

                         <Box
                         flex
                         ></Box>

                </Box> */}

                {/* <DeveloperChoiceProducts /> */}
                <FeaturedProducts />
                <HomeFooter />
                <LoginModal
                    isOpen={showLogin}
                    refreshAfter={false}
                    redirectToDashboardAfter={true}
                    preMode={preMode}
                    onClose={() => {
                        setShowLogin(false)
                    }}
                />
                <PopupGuide isOpen={showPopupAD} onClose={() => {
                    localStorage.setItem('ct-ad-showed', 'true')
                    setShowPopupAD(false)
                    setShowLogin(true)
                    setPreMode('signup')
                }} />
                <LiveChatWidget license='15929757' />
            </Box>
        </div>
    )
})
export default Home
