import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { LS } from '../../../common/LocalStorage';
import {
    ColorOption,
    NewOriginalProduct,
    OPCategory,
    OriginalProductItemVO,
    PreviewImageItem,
    RawImageElemet,
    ShippingMethodOption,
    SizeOption,
    SubSKUProduct,
    TagOption,
    Volume,
} from '../../../TypeDeclare';
import {
    getCategories,
    getOriginalProducts,
    getSNC,
    updateOriginalProduct,
    uploadOriginalProduct,
} from '../services/originalProductUploaderService';
import { EditorService } from '../../editor/services/editorService';
import { OPConditionChecker } from './OPConditionChecker';
import {
    getProductByID,
    parseVolume,
    parseWeight,
} from '../../product_list/services/productService';

interface formItemList {
    id: number
    psdSku: string,
    layer: {
        image: File | null,
        comment: string
    },

}

interface maskItemList {
    id: number
    mask: {
        image: File | null | string,
        comment: string,
        productSize: Array<number>,
        actualSize: Array<number>
    }
}
export class OriginalProductStore extends LS {
    @observable productName: string = '';
    @observable pairPrice: number = 0;

    // @observable shippingFee: number = 0;
    @observable description: string = '';
    @observable descriptionHtml: string = '';
    @observable designYourOwn: boolean = true;
    @observable fullPrint: boolean = false;
    @observable printOnDemand: boolean = true;
    @observable shipsFrom: string = 'US';
    @observable sizes: Array<SizeOption> = [];
    @observable colors: Array<ColorOption> = [];
    @observable coverImages: Array<string> = [];
    @observable sizeTableImages: Array<string> = [];
    @observable editableImages: Array<string> = [];
    @observable volume: Volume = { length: 0, height: 0, width: 0 };
    @observable weight: number = 0;

    // @observable coverImageFile: File | undefined = undefined;
    // @observable sizeTableImageFile: File | undefined = undefined;

    @observable editableImageFiles: Array<File | undefined> = [];
    //20231205 added to support multiple images
    @observable coverImageFiles: Array<File | undefined> = [];
    @observable sizeTableImageFiles: Array<File | undefined> = [];
    @observable maskImageFiles: Array<string> = [];
    @observable layerImageFiles: Array<string> = [];
    @observable psd_sku: Array<string> = [];
    @observable formItemList: Array<formItemList> = [{
        id: 1,
        psdSku: '',
        layer: {
            image: null,
            comment: ''
        },
    }];
    @observable maskItemList: Array<maskItemList> = [{
        id: 1,
        mask: {
            image: null,
            comment: '',
            productSize: [0, 0],
            actualSize: [0, 0]
        }
    }];

    @observable tags: Array<TagOption> = [];
    @observable sizeRange: string = '';

    // options for user to choose from
    @observable categoryOptionsLevel1: Array<OPCategory> = [];
    private _categoryOptionsLevel2: Array<OPCategory> = [];
    @observable selectedParentCategory: OPCategory | undefined = undefined;
    @observable selectedChildCategory: OPCategory | undefined = undefined;

    @observable colorOptions: Array<ColorOption> = [];
    @observable sizeOptions: Array<SizeOption> = [];
    @observable tagOptions: Array<TagOption> = [];
    @observable shippingMethodsOptions: Array<ShippingMethodOption> = [];

    private uploadImageService: EditorService = new EditorService();
    categoriesLoaded: boolean = false;

    @observable private quantityIDMap: Map<string, number> = new Map<
        string,
        number
    >();
    @observable private skuPriceIDMap: Map<string, number> = new Map<
        string,
        number
    >();

    @observable originalProducts: OriginalProductItemVO[] = [];
    @observable originalProductsPageCount: number = 1;
    @observable originalProductsCurrentPage: number = 1;

    checker: OPConditionChecker;
    constructor() {
        super();
        this.checker = new OPConditionChecker(this);
        makeObservable(this);
    }
    @action
    demo() {
        runInAction(() => {
            this.productName = 'Demo';
            this.pairPrice = 10;
            this.description = 'Demo Desc';
            this.designYourOwn = true;
            this.fullPrint = false;
            this.printOnDemand = true;
            this.shipsFrom = 'US';
            this.sizes = [{ id: '1', value: 'XS' }];
            this.colors = [{ id: '1', value: '#fff', name: 'White' }];
            this.selectedParentCategory = {
                id: '15',
                name: 'Accessories',
                parentId: '',
            };
            this.selectedChildCategory = {
                id: '17',
                name: 'Bags',
                parentId: '15',
            };
            this.weight = 1;
            this.volume = { width: 1, height: 1, length: 1 };
            this.sizeRange = 'XS - XS'
        });
    }

    @action
    reset() {
        runInAction(() => {
            this.productName = '';
            this.pairPrice = 0;
            this.description = '';
            this.descriptionHtml = '';
            this.designYourOwn = true;
            this.fullPrint = false;
            this.printOnDemand = true;
            this.shipsFrom = 'US';
            this.sizes = [];
            this.colors = [];
            this.coverImages = [];
            this.coverImageFiles = [];
            this.editableImages = [];
            this.editableImageFiles = [];
            this.sizeTableImageFiles = [];
            this.sizeTableImages = [];
            this.selectedParentCategory = undefined;
            this.selectedChildCategory = undefined;
            this.weight = 0;
            this.volume = { width: 0, height: 0, length: 0 };
            this.formItemList = []
            this.maskItemList = []
            this.maskImageFiles = [];
            this.layerImageFiles = [];
            this.psd_sku = [];
        });
    }

    @action
    setWeight(value: number) {
        runInAction(() => {
            this.weight = value;
        });
    }
    @action
    setFormItemList(value: Array<formItemList>) {
        runInAction(() => {

            this.formItemList = value;
        });
    }

    @action
    setMaskItemList(value: Array<maskItemList>) {
        runInAction(() => {

            this.maskItemList = value;
        });
    }

    @action
    setVolume(value: number, field: 'width' | 'height' | 'length') {
        runInAction(() => {
            this.volume = { ...this.volume, [field]: value };
        });
    }

    @action
    setSizeRange(value: string) {
        runInAction(() => {
            this.sizeRange = value;
        });
    }

    @action
    setTags(newTags: Array<TagOption>) {
        runInAction(() => {
            this.tags = newTags;
        });
    }

    @action
    setEditableImageFiles(files: Array<File | undefined>) {
        runInAction(() => {
            this.editableImageFiles = files;
        });
    }
    @action
    setMaskImageFiles(files: Array<string>) {
        runInAction(() => {
            this.maskImageFiles = files;
        });
    }
    @action
    setPsdSkuFiles(files: Array<string>) {
        runInAction(() => {
            this.psd_sku = files;
        });
    }
    @action
    setLayerImageFiles(files: Array<string>) {
        runInAction(() => {
            this.layerImageFiles = files;
        });
    }
    @action
    setCoverImageFiles(files: Array<File | undefined>) {
        runInAction(() => {
            this.coverImageFiles = files;
        });
    }
    @action
    setSizeTableImageFiles(files: Array<File | undefined>) {
        runInAction(() => {
            this.sizeTableImageFiles = files;
        });
    }


    @action
    setSelectedParentCategory(value: OPCategory) {
        runInAction(() => {
            this.selectedParentCategory = value;
        });
    }

    @action
    setSelectedChildCategory(value: OPCategory | undefined) {
        runInAction(() => {
            this.selectedChildCategory = value;
        });
    }

    @action
    setProductName(value: string) {
        runInAction(() => {
            this.productName = value;
        });
    }

    @action
    setPairPrice(value: number) {
        runInAction(() => {
            this.pairPrice = value;
        });
    }

    @action
    setDescription(value: string) {
        runInAction(() => {
            this.description = value;
        });
    }

    @action
    setDescriptionHtml(value: string) {
        runInAction(() => {
            this.descriptionHtml = value;
        });
    }

    @action
    setDesignYourOwn(value: boolean) {
        runInAction(() => {
            this.designYourOwn = value;
        });
    }

    @action
    setFullPrint(value: boolean) {
        runInAction(() => {
            this.fullPrint = value;
        });
    }

    @action
    setPrintOnDemand(value: boolean) {
        runInAction(() => {
            this.printOnDemand = value;
        });
    }

    @action
    setShipsFrom(value: string) {
        runInAction(() => {
            this.shipsFrom = value;
        });
    }

    @action
    setSizes(value: Array<SizeOption>) {
        runInAction(() => {
            this.sizes = value;
        });
    }

    @action
    setColors(value: Array<ColorOption>) {
        runInAction(() => {
            this.colors = value;
        });
    }


    @action
    setEditableImages(value: Array<string>) {
        runInAction(() => {
            this.editableImages = value;
        });
    }

    @action
    setCoverImages(value: Array<string>) {
        runInAction(() => {
            this.coverImages = value;
        });
    }
    @action
    setSizeTableImages(value: Array<string>) {
        runInAction(() => {
            this.sizeTableImages = value;
        });
    }

    @action
    updateQuantityOfItem(item: SubSKUProduct, quantity: number) {
        runInAction(() => {
            this.quantityIDMap.set(item.sku, quantity);
        });
    }
    @action
    updateSKUPriceOfItem(item: SubSKUProduct, price: number) {
        runInAction(() => {
            this.skuPriceIDMap.set(item.sku, price);
        });
    }
    @computed
    get shippingFee(): number {
        return 0;
    }
    @computed
    get categoryOptionsLevel2(): Array<OPCategory> {
        if (this.selectedParentCategory === undefined) return [];
        return this._categoryOptionsLevel2.filter((co1) => {
            return co1.parentId === this.selectedParentCategory?.id;
        });
    }
    @computed
    get allSubSKUProducts(): Array<SubSKUProduct> {
        const result: Array<SubSKUProduct> = [];

        for (let i = 0; i < this.sizes.length; i++) {
            const size = this.sizes[i];
            for (let j = 0; j < this.colors.length; j++) {
                const color = this.colors[j];
                const sku = `${color.id}-${size.id}`;
                const qFromMap = this.quantityIDMap.get(sku);
                const quantity = qFromMap === undefined ? 1 : qFromMap;
                const spFromMap = this.skuPriceIDMap.get(sku);
                //计算skuPrice的办法因为只有pairPrice
                const skuPrice = spFromMap || this.pairPrice;
                const skuFull = `${color.name}&${size.value},$${this.pairPrice},*${quantity}`;
                result.push({
                    color: { ...color },
                    size: { ...size },
                    quantity: quantity,
                    sku,
                    skuPrice: skuPrice,
                    skuFull: skuFull,
                });
            }
        }
        return result;
    }

    @computed
    get newOriginalProduct(): NewOriginalProduct {
        return {
            category: this.selectedChildCategory?.id || '',
            productName: this.productName,
            pairPrice: this.pairPrice,
            description: this.description,
            descriptionHtml: this.descriptionHtml,
            tags: this.tags,
            designYourOwn: this.designYourOwn,
            fullPrint: this.fullPrint,
            printOnDemand: this.printOnDemand,
            shipsFrom: this.shipsFrom,
            sizes: this.sizes,
            colors: this.colors,
            coverImages: this.coverImages,
            editableImages: this.editableImages,
            volume: this.volume,
            weight: this.weight,
        };
    }
    @computed
    get shippingMethodToSubmit(): string {
        return this.shippingMethodToFormString(this.shippingMethodsOptions);
    }
    shippingMethodToFormString(options: ShippingMethodOption[]): string {
        const object: { [key: string]: any } = {};
        options.forEach((smo) => {
            const selected = smo.options.find((o) => o.id === smo.selected);
            object[smo.name] = selected?.fee.toString();
        });
        return JSON.stringify(object);
    }
    async uploadProduct(token: string) {
        const imagesComments = ['FRONT', 'BACK', 'SIDE'];
        const formData = new FormData();
        formData.append('name', this.productName);
        formData.append('short_description', this.description);
        formData.append('description_html', this.descriptionHtml);
        // 在上传产品的 时候，你就只需要传default_price, 我后台会把这个当做pair_price 来处理，去计算所有其他的价格。by hang
        formData.append('default_price', this.pairPrice.toString());

        formData.append('tags', JSON.stringify(this.tags.map((t) => t.id)));
        formData.append('is_customizable', this.designYourOwn ? '1' : '0');
        formData.append('is_fullprint', this.fullPrint ? '1' : '0');
        formData.append('ready_to_ship', this.printOnDemand ? '0' : '1');
        formData.append('size_range', this.sizeRange);
        formData.append('shipping_options', this.shippingMethodToSubmit);

        const psdskus = this.formItemList.map(item => item.psdSku)
        formData.append('psd_sku', JSON.stringify(psdskus))
        // formData.append('cover_images', JSON.stringify(this.coverImages));
        // formData.append('size_images', JSON.stringify(this.sizeTableImages));
        formData.append('cover_image', this.coverImages[0]);
        if (this.sizeTableImageFiles.length > 0) {
            formData.append('description_image', this.sizeTableImageFiles[0] as File);
        }

        // todo
        const maskList = this.maskItemList.map((item) => {
            return item.mask
        })
        const maskParams: { image: string, comment: string }[] = []
        maskList.forEach((item, index) => {
            maskParams.push({
                image: this.maskImageFiles[index],

                comment: 'psd clipping mask ' + item.comment + ' ' + item.productSize[0] + '*' + item.productSize[1] + ' ' + item.actualSize[0] + '*' + item.actualSize[1]
            })

        })
        const layerList = this.formItemList.map((item) => {
            return item.layer
        })
        const layerParams: { image: string, comment: string }[] = []
        layerList.forEach((item, index) => {
            layerParams.push({
                image: this.layerImageFiles[index],
                comment: this.formItemList[index].layer.comment
            })

        })

        const editableImagesList = this.editableImages.map((image, index) => ({
            image,
            comment: imagesComments[index],
        }))
        const images = [...editableImagesList, ...layerParams, ...maskParams]
        formData.append(
            'images',
            JSON.stringify(images)

        );
        if (this.selectedChildCategory) {
            formData.append('category', this.selectedChildCategory?.id);
        }
        formData.append('description', this.description);
        const product_skus = this.allSubSKUProducts.map((p) => {
            return {
                attributes: [p.size.id, p.color.id],
                // pair_price: this.pairPrice,
            };
        });
        formData.append('product_skus', JSON.stringify(product_skus));

        //TODO: add shipping info
        formData.append('weight', this.weight.toString());
        formData.append('volume', JSON.stringify(this.volume));
        // let result = { success: false }
        const result = await uploadOriginalProduct(token, formData);
        return result;
    }
    async getCategories(token: string) {
        if (this.categoriesLoaded) return;
        this.categoriesLoaded = true;
        const result = await getCategories(token);
        const topCategories: Array<OPCategory> = [];
        const subCategories: Array<OPCategory> = [];
        result.data.forEach((element: any) => {
            topCategories.push({
                id: element['id'],
                name: element['name'],
                parentId: '',
            });
            const children = element['children'].map((subitem: any) => {
                return {
                    id: subitem['id'],
                    name: subitem['name'],
                    parentId: element['id'],
                };
            });
            subCategories.push(...children);
        });
        runInAction(() => {
            this.categoryOptionsLevel1 = topCategories;
            this._categoryOptionsLevel2 = subCategories;
        });
    }

    async getOriginalProducts(token: string, page: number) {
        runInAction(() => {
            this.originalProductsCurrentPage = page;
        });
        const result = await getOriginalProducts(token, page);

        const products: OriginalProductItemVO[] = result.data.map(
            (data: any): OriginalProductItemVO => {

                //@ts-ignore
                return {
                    id: data['id'],
                    name: data['name'],
                    categoryName: data['category_name'],
                    costPrice: Number(data['cost_price']),
                    defaultPrice: Number(data['default_price']),
                    pairPrice: Number(data['pair_price']),
                    productId: data['product_id'],
                    descShorten: data['short_description'],
                    coverImages: [data['cover_image']],//TODO parse cover_images later
                    images: [],
                    shippingOptions: [],
                    weight: parseWeight(data),
                    volume: parseVolume(data),
                    rawImageURLs: [],
                    // psdSku: data['psd_sku'] ? JSON.parse(data['psd_sku']) : [],
                    // psdClippingMasks: data['psd_clipping_masks'] ? JSON.parse(data['psd_clipping_masks']) : [],
                    // psdLayerImages: data['psd_layer_images'] ? JSON.parse(data['psd_layer_images']) : [],
                };
            }
        );

        runInAction(() => {
            this.originalProducts = products;
            this.originalProductsPageCount = Math.ceil(
                Number(result['total_count']) / 20
            );
        });
    }
    @action
    async getSizesNColors(token: string) {
        const result = await getSNC(token);
        const colors: Array<ColorOption> = result.attribute_values['2'].map(
            (item: any) => {
                return {
                    id: item.value_id,
                    name: item.value,
                    value: item.comment,
                };
            }
        );
        const sizes: Array<SizeOption> = result.attribute_values['1'].map(
            (item: any) => {
                return {
                    id: String(item.value_id),
                    value: item.value,
                };
            }
        );
        const tagsOptions: Array<TagOption> = result.tags.map((t: any) => {
            return {
                id: t.id,
                value: t.name,
            };
        });
        const distinctColors = colors.filter(
            (value, index, self) =>
                self.findIndex((v) => v.name === value.name) === index
        );

        const distinctSizes = sizes.filter(
            (value, index, self) => self.findIndex((v) => v.id === value.id) === index
        );

        this.parseShippingMethods(result.shipping_options);

        runInAction(() => {
            this.colorOptions = distinctColors;
            this.sizeOptions = distinctSizes;
            this.tagOptions = tagsOptions;
        });
    }
    @action
    parseShippingMethods(options: any) {
        const smos: ShippingMethodOption[] = [];
        let count = 0;
        for (const key in options) {
            if (Object.prototype.hasOwnProperty.call(options, key)) {
                const element = options[key];
                smos.push({
                    id: `shippingmethod-${count}`,
                    name: key,
                    comment: element.comment,
                    options: element.details.map((d: any, i: number) => {
                        return {
                            id: `${key}-${i}`,
                            fee: d.fee,
                            weightComment: d['weight_comment'],
                        };
                    }),
                    selected: `${key}-${0}`,
                });
                count++;
            }
        }
        runInAction(() => {
            this.shippingMethodsOptions = smos;
        });
    }
    @action
    updateShippingMethodsOptionsSelection(
        target: ShippingMethodOption,
        to: string
    ) {
        const newSMOs = this.shippingMethodsOptions.map((smo) => {
            if (smo.id === target.id) {
                return {
                    ...smo,
                    selected: to,
                };
            }
            return {
                ...smo,
            };
        });
        runInAction(() => {
            this.shippingMethodsOptions = newSMOs;
        });
    }

    @action
    async loadAndParseProduct(
        existedProduct: OriginalProductItemVO
    ): Promise<OriginalProductItemVO> {
        const { product } = await getProductByID(existedProduct.id);

        const choosenShippingOptions = product['shipping_options'];
        const newSMOs = [...this.shippingMethodsOptions].map((smo) => {
            if (choosenShippingOptions[smo.name]) {
                const fee = choosenShippingOptions[smo.name]['shipping_fee'];
                const option = smo.options.find((o) => o.fee === Number(fee));
                return {
                    ...smo,
                    selected: option ? option.id : smo.options[0].id,
                };
            }
            return smo;
        });

        runInAction(() => {
            this.originalProducts = this.originalProducts.map((o) => {
                if (o.id === product.id) {
                    const coverImages: string[] = Array.isArray(product['cover_images']) ? product['cover_images'] : [product['cover_image']]
                    return {
                        ...o,
                        images: product['image_urls'].map((p: any) => p.image),
                        coverImages,
                        rawImageURLs: product['image_urls'] as RawImageElemet[],
                        shippingOptions: newSMOs,
                        costPrice: Number(product['cost_price']),
                    };
                }
                return o;
            });
        });
        const coverImages: string[] = Array.isArray(product['cover_images']) ? product['cover_images'] : [product['cover_image']]

        let psdSku = []
        if (product['psd_sku']) {
            if (typeof product['psd_sku'] === 'string') {
                psdSku = JSON.parse(product['psd_sku'])
            } else {
                psdSku = product['psd_sku']
            }
        }
        return {
            ...existedProduct,
            images: product['image_urls'].map((p: any) => p.image),
            shippingOptions: newSMOs,
            coverImages,
            rawImageURLs: product['image_urls'] as RawImageElemet[],
            costPrice: Number(product['cost_price']),
            psdSku,
            psdClippingMasks: product['psd_clipping_masks'] ? product['psd_clipping_masks'] : [],
            psdLayerImages: product['psd_layer_images'] ? product['psd_layer_images'] : [],
            description_html: product['description_html'] || ''

        };
    }
    @action
    async updateOriginalProduct(item: OriginalProductItemVO, token: string) {

        const formData = new FormData();
        formData.append('name', item.name);
        // @ts-ignore
        formData.append('psd_sku', JSON.stringify(item.psd_sku));
        formData.append('pair_price', item.pairPrice.toString());
        formData.append(
            'shipping_options',
            this.shippingMethodToFormString(item.shippingOptions)
        );
        formData.append('weight', item.weight.toString());
        formData.append('short_description', item.descShorten);
        formData.append('description_html', item.description_html);
        formData.append('volume', JSON.stringify(item.volume));
        formData.append('cover_images', JSON.stringify(item.coverImages));
        formData.append('image_urls', JSON.stringify(item.rawImageURLs));
        const result = await updateOriginalProduct(item.id, token, formData);
        return Boolean(result.data.success);
        // return false;
    }

    async uploadImages(
        files: Array<File | undefined>,
        onProgress: (p: number) => void
    ) {
        const readyFiles = files.filter((file) => file !== undefined);
        const tasks = readyFiles.map((file) => {
            return this.uploadImageService.fileToBase64(file!);
        });
        const base64Data = await Promise.all(tasks);

        const previewImages: PreviewImageItem[] = base64Data.map((data, index) => {
            const file = readyFiles[index];
            return {
                fileName: `base64_image_${index}`,
                name: `base64_image_${index}`,
                data: data.split(',')[1],
                alias: `base64_image_${index}`,
                format: file!.type.split('/')[1],
            };
        });
        const result = await this.uploadImageService.uploadImageItems(
            previewImages,
            onProgress
        );
        return result;
    }
}
