import { FC } from 'react'
import { Box } from '@fower/react'
import {
    FormControlLabel,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import EmptyNumberInput from './EmptyNumberInput'
import { ShippingMethodOption } from '../../../../TypeDeclare'
import ShippingMethodsPicker from './ShippingMethodsPicker'
import QuillEditor from './Editor'

export type InfoInputProps = {
    previewMode: boolean
    productName: string
    updateProductName: (productName: string) => void
    categories: string
    pairPrice: number
    updatePairPrice: (pairPrice: number) => void
    designYourOwn: boolean
    updateDesignYourOwn: (designYourOwn: boolean) => void
    fullPrint: boolean
    updateFullPrint: (fullPrint: boolean) => void
    printOnDemand: boolean
    updatePrintOnDemand: (printOnDemand: boolean) => void
    shipsFrom: string
    updateShipsFrom: (shipsFrom: string) => void
    description: string
    updateDescription: (description: string) => void
    shippingMethodsOptions: Array<ShippingMethodOption>
    onShippingMethodsOptionUpdate?: (item: ShippingMethodOption, to: string) => void
    descriptionHtml: string
    updateDescriptionHtml: (descriptionHtml: string) => void
}

const InfoInput: FC<InfoInputProps> = observer(
    ({
        previewMode,
        productName,
        updateProductName,
        categories,

        pairPrice,
        updatePairPrice,

        designYourOwn,
        updateDesignYourOwn,
        fullPrint,
        updateFullPrint,
        printOnDemand,
        updatePrintOnDemand,
        shipsFrom,
        updateShipsFrom,
        description,
        updateDescription,
        shippingMethodsOptions,
        onShippingMethodsOptionUpdate,
        descriptionHtml,
        updateDescriptionHtml
    }) => {

        return (
            <Box w="100%" h="100%" toCenterX column p2 spaceY2>
                <TextField
                    type="text"
                    label={'Product Name'}
                    variant="standard"
                    sx={{ width: '100%' }}
                    value={productName}
                    onChange={(e) => {
                        updateProductName(e.target.value)
                    }}
                    InputProps={{
                        readOnly: previewMode,
                    }}
                />
                <TextField
                    type="text"
                    label={'Categories'}
                    variant="standard"
                    sx={{ width: '100%' }}
                    value={categories}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <EmptyNumberInput
                    value={pairPrice}
                    label={'Pair Price'}
                    sx={{ width: '100%' }}
                    previewMode={previewMode}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    onUpdate={(newValue) => {
                        updatePairPrice(newValue)
                    }}
                />
                {/* Shipping methods */}
                <ShippingMethodsPicker
                    shippingMethodsOptions={shippingMethodsOptions}
                    onUpdate={(item, to) => {
                        onShippingMethodsOptionUpdate && onShippingMethodsOptionUpdate(item, to)
                    }}
                    disable={previewMode} />
                {/* Description */}
                <TextField
                    label="Description"
                    multiline
                    rows={3}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    value={description}
                    onChange={(e) => updateDescription(e.target.value)}
                    InputProps={{
                        readOnly: previewMode,
                    }}
                />

                <QuillEditor
                    html={descriptionHtml}
                    onGetHtml={(data) => updateDescriptionHtml(data)}
                />

                <Box w="100%" row toBetween>
                    <FormControlLabel
                        labelPlacement="start"
                        label={'Design Your Own'}
                        control={
                            <Switch
                                onChange={(e) => updateDesignYourOwn(e.target.checked)}
                                checked={designYourOwn}
                                disabled={previewMode}
                            />
                        }
                        sx={{ ml: 0, mr: 1 }}
                    />
                    <FormControlLabel
                        labelPlacement="start"
                        label={'Full Print'}
                        control={
                            <Switch
                                onChange={(e) => updateFullPrint(e.target.checked)}
                                checked={fullPrint}
                                disabled={previewMode}
                            />
                        }
                    />
                </Box>
                <Box row toCenter w="100%">
                    <RadioGroup
                        row={true}
                        defaultValue={printOnDemand ? '0' : '1'}
                        onChange={(_, value) => {
                            updatePrintOnDemand(value === '0')
                        }}
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio disabled={previewMode} />}
                            label="Print on Demand"
                            labelPlacement="start"
                            sx={{ ml: 0 }}
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio disabled={previewMode} />}
                            label="Ready to ship"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </Box>
                <Box row w="100%" toCenterY toLeft>
                    Ships from
                    <Select
                        value={shipsFrom}
                        onChange={(e) => updateShipsFrom(e.target.value)}
                        inputProps={{
                            'aria-label': 'Without label',
                            readOnly: previewMode,
                        }}
                        sx={{ height: '38px', ml: 1 }}
                    >
                        <MenuItem value={'US'}>US</MenuItem>
                        <MenuItem value={'China'}>China</MenuItem>
                        <MenuItem value={'Mexico'}>Mexico</MenuItem>
                    </Select>
                </Box>
            </Box>
        )
    },
)
export default InfoInput
