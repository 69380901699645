import { Box } from "@fower/react";
import { CSSProperties, FC, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode } from "../../TypeDeclare";
import HomeFooter from "../home/Footer";
import LoginModal from "../../common/LoginModal";
import { Typography } from "@mui/material";

const AboutUs: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const data = [
        {
            title: '20000+',
            desc: `Over 20,000 happy <br /> customers.`
        },
        {
            title: '300+ SKUs',
            desc: `We offer over 300 SKUs to meet <br /> your everyday essentials.`
        },
        {
            title: '50 States',
            desc: `We ship our products to all <br /> 50 states.`
        },
        {
            title: '1',
            desc: `Google & GSV Pioneer <br /> Accelerator Alumni.`
        }
    ]

    const icons = [
        {
            url: '/images/icon1.png',
            width: '80px',
            height: '82px'
        },
        {
            url: '/images/icon2.png',
            width: '45px',
            height: '75px'
        },
        {
            url: '/images/icon3.png',
            width: '101px',
            height: '76px'
        },
        {
            url: '/images/icon4.png',
            width: '57px',
            height: '76px'
        },
        {
            url: '/images/icon5.png',
            width: '58px',
            height: '75px'
        },
    ]

    const styleObj: Record<string, CSSProperties> = {
        aboutCustomTee: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 'bold',
            fontSize: '30px',
            color: '#000000',
        },
        aboutContent: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: '600',
            fontSize: '20px',
            color: '#373737'
        },
        mt50: {
            marginTop: '50px'
        },
        title: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 'bold',
            fontSize: '50px',
            color: '#518DD9',
            margin: '0'
        },
        desc: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: '600',
            fontSize: '14px',
            color: '#000',
            marginTop: '10px'
        },
        contactTitle: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 'bold',
            fontSize: '30px',
            color: '#000',
            margin: '0 0 20px 0'
        },
        emailAndAddress: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontSize: '20px',
            color: '#000',
        },
        icons: {
            // width: '20%',
            display: 'block',
            // margin: '0'
        },
        timelineTxt: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontSize: '14px',
            color: '#000000'
        }
    }

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />


            <img src="/images/About2.png" style={{ width: '100%' }} />

            <Box
                w="80%"
                flex
                flexWrap
                alignItems="center"
                style={styleObj.mt50}
            >
                <Box
                    w={["100%", "100%", "100%", "48%", "48%"]}
                >
                    <Typography variant="subtitle1" style={styleObj.aboutCustomTee} gutterBottom>
                        Your Reliable Print on Demand Fulfillment Partner
                    </Typography>

                    <Typography variant="body1" style={styleObj.aboutContent} gutterBottom>
                        At Custom Tee, we are committed to delivering excellence through our in-house brand of blank products, ensuring the highest quality and consistency for every item. By leveraging our state-of-the-art print-on-demand factory in China, we can lower your printing costs and offer better pricing for sellers. This allows us to quickly fulfill orders, enabling rapid turnaround times. Our California warehouse ensures efficient shipping to all 50 states, guaranteeing quick delivery and exceptional after-sales customer service. Whether you’re a small business or an individual seeking custom apparel, we’re here to provide the reliability and quality you deserve.
                    </Typography>
                </Box>
                <Box w={['0%', '0%', '0%', '2%', '2%']}></Box>
                <Box
                    w={["100%", "100%", "100%", "48%", "48%"]}
                >
                    <img src="/images/aboutCustomTee.png" style={{
                        width: '100%'
                    }} alt="aboutCustomTee" />
                </Box>
            </Box>

            <Box
                w="100%"
                flex
                flexWrap
                alignItems="center"
                justifyContent="center"
                style={{
                    ...styleObj.mt50,
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/aboutUsBack.png')`,
                    // height: '170px',
                    backgroundSize: 'cover',
                    border: '1px dashed #000'
                }}
            >

                <Box
                    w="80%"
                    flex
                    flexWrap
                    alignItems="center"
                    justifyContent="space-around"
                >
                    {
                        data.map((item, index) => {
                            return (
                                <Box
                                    key={index}
                                    flex
                                    column
                                    alignItems="center"
                                    style={{
                                        margin: '0 20px'
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom style={styleObj.title}>
                                        {item.title}
                                    </Typography>
                                    <Box w="40px" h="6px" style={{
                                        background: '#518DD9'
                                    }}></Box>
                                    <Typography
                                        dangerouslySetInnerHTML={{ __html: item.desc }}
                                        style={styleObj.desc}
                                        variant="body1" gutterBottom textAlign="center">

                                    </Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>

            <Box
                w="80%"
                style={styleObj.mt50}
            >
                <Typography variant="h1" textAlign='center' gutterBottom style={styleObj.contactTitle}>
                    Custom Tee Brand Blanks
                </Typography>
                <Box
                    flex
                    flexWrap
                    justifyContent='space-between'
                >
                    <img src="/images/test1.png" style={{ width: '49%' }} />
                    <img src="/images/test2.png" style={{ width: '49%' }} />
                </Box>
                <Box
                    flex
                    flexWrap

                >

                    {
                        icons.map((item, index) => {
                            return (
                                <Box w={['50%', '20%', '20%', '20%', '20%']}
                                    key={index}
                                    flex
                                    justifyContent="center"
                                    mt4
                                >
                                    <img src={item.url} style={{ width: item.width, height: item.height }} />
                                </Box>
                            )
                        })
                    }

                </Box>

                <Box
                    style={styleObj.mt50}
                    p8
                    w="100%"
                    css={{
                        background: '#E6E6E6',
                        fontSize: '20px',
                        fontFamily: 'Segoe UI, Segoe UI',
                        lineHeight: '24px'
                    }}
                >
                    Our Custom Tee brand blanks are made from premium fabrics and uphold high standards to ensure consistency in every piece. This commitment to quality provides the perfect foundation for your custom designs.
                </Box>
            </Box>

            <Box
                w="80%"
                style={styleObj.mt50}
            >
                <Typography variant="h1" textAlign='center' gutterBottom style={styleObj.contactTitle}>
                    Our Teamline
                </Typography>

                <Box
                    flex
                    display={['block', 'block', 'flex', 'flex', 'flex']}
                    justifyContent="space-between"
                >
                    <img src="/images/team.png" style={{ width: '100%', display: 'block' }} />
                </Box>
            </Box>

            <Box w="80%" style={styleObj.mt50}>

                <img src="/images/timeLine.png" style={{ width: '100%' }} />
            </Box>



            <Box
                flex
                column
                toCenter
                style={styleObj.mt50}
            >
                <Typography variant="h1" gutterBottom style={styleObj.contactTitle}>
                    Contact Us
                </Typography>

                <Typography variant="body1" textAlign='center' gutterBottom style={styleObj.emailAndAddress}>
                    <span style={{ fontWeight: 'bold' }}>Email:</span>
                    <a>Hello@customtee.co</a>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Address:</span>
                    <span>585 Broadway, Redwood City CA 94063</span>
                </Typography>
            </Box>

            <Box h="100px"></Box>

            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box >
    )
}

export default AboutUs;