import { FC, useCallback, useState } from 'react'
import { Box } from '@fower/react'
import {
  DBShopifyOrderVO,
  PreviewImageItem,
  ShopifyFulfillmentDetailVO,
} from '../../../TypeDeclare'
import { Button, Divider, TextField } from '@mui/material'
import { format } from 'date-fns'
import { PreviewImagesBar } from '../../checkout/PreviewImagesBar'

export type ShopifyOrderDetailItemProps = {
  data: ShopifyFulfillmentDetailVO
  order: DBShopifyOrderVO
  hasTrackingLink?: boolean
  onAddTrackingLink?: (newLink: string) => Promise<boolean>
}
const ShopifyOrderDetailItem: FC<ShopifyOrderDetailItemProps> = ({
  data,
  order,
  hasTrackingLink = false,
  onAddTrackingLink,
}) => {
  const [trackingLink, setTrackingLink] = useState<string>(
    data.shipping_label || '',
  )
  const getPreviewImages = useCallback((): PreviewImageItem[] => {
    return data.product_images.split(',').map((pi, i) => {
      return {
        name: `assets-${i}`,
        url: pi,
        fileName: '',
        alias: '',
        format: 'png',
      }
    })
  }, [data.product_images])
  return (
    <Box p4 column border-2 rounded spaceY2 shadow>
      <Box toBetween>
        <Box column spaceY2>
          <Box row toBetween>
            <Box fontBold>Order ID:</Box> <Box>{data.fulfillment_order_id}</Box>
          </Box>
          <Box row toBetween>
            <Box fontBold>SKU:</Box> <Box>{data.product_sku}</Box>
          </Box>
        </Box>
        {/* <StyledImage
          w32
          h32
          src={data.cover_image}
          bgWhite
          shadow
          style={{
            objectFit: 'contain',
          }}
          zIndex={1}
        /> */}
      </Box>
      <PreviewImagesBar imageData={getPreviewImages()} onClick={() => {}} />
      <Box row toBetween>
        <Box fontBold>Product name:</Box> <Box>{data.name}</Box>
      </Box>
      <Box row toBetween>
        <Box fontBold>Variants: </Box>
        <Box>{data.variant_title}</Box>
      </Box>

      <Box row toBetween>
        <Box fontBold>Quantity: </Box>
        <Box>{data.quantity}</Box>
      </Box>

      {/* <Divider /> */}
      {/* <Box row toBetween>
        <Box fontBold>Your Retail Price: </Box>
        <Box>$ {data.sale_price}</Box>
      </Box> */}
      <Box row toBetween>
        <Box fontBold>Production Cost:</Box> <Box>$ {data.cost_price}</Box>
      </Box>
      {/* <Box row toBetween>
        <Box fontBold>Shipping Fee: </Box>
        <Box>$ {data.shipping}</Box>
      </Box> */}
      <Box row toBetween>
        <Box fontBold>Sent to production:</Box>{' '}
        <Box>{format(order.updateTime, 'MM/dd/yy HH:mm:ss')}</Box>
      </Box>
      <Divider />
      <Box row toBetween>
        <Box fontBold>Customer:</Box>
        <Box>{`${order.destination.first_name || ''} ${
          order.destination.last_name || ''
        }`}</Box>
      </Box>
      <Box>
        <Box
          fontBold
          style={{
            lineBreak: 'auto',
            lineHeight: '32px',
          }}
        >
          Shipping address:
        </Box>
        <Box>{`${
          order.destination.address1 ? `${order.destination.address1},` : ''
        }${
          order.destination.address2 ? `${order.destination.address2},` : ''
        } ${order.destination.city ? `${order.destination.city},` : ''} ${
          order.destination.province ? `${order.destination.province}` : ''
        } ${order.destination.zip || ''} ${
          order.destination.country || ''
        }`}</Box>
      </Box>
      <Box row toBetween>
        <Box fontBold>Phone number: </Box>
        <Box>{order.destination.phone}</Box>
      </Box>
      {hasTrackingLink && (
        <Box row spaceX2>
          <TextField
            label="Tracking Link"
            variant="outlined"
            onChange={(event) => {
              setTrackingLink(event.target.value as string)
            }}
            value={trackingLink}
            sx={{
              flexGrow: 2,
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (onAddTrackingLink) {
                onAddTrackingLink(trackingLink || '').then((success) => {
                  if (!success) {
                    setTrackingLink('')
                  }
                })
              }
            }}
          >
            Update
          </Button>
        </Box>
      )}
    </Box>
  )
}
export default ShopifyOrderDetailItem
