import { FC } from "react"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from "@fower/react";
import { styled } from '@mui/material/styles';
import CircularWithValueLabel from './CircularProgressWithLabel'

interface UploadDialogProps {
    open: boolean;
    progress: number;
    cancelClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        // padding: '200px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const UploadDialog: FC<UploadDialogProps> = ({
    open, cancelClose, progress
}) => {
    return (
        <BootstrapDialog

            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={cancelClose}
        >

            <DialogContent style={{ width: '600px', height: '280px', borderRadius: '17px' }}>
                <Box style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <CircularWithValueLabel progress={progress} />
                </Box>
                <Box
                    w="100%"
                    h="100%"
                    flex
                    column
                    justifyContent="space-around"
                >

                    <Box w="100%" flex toCenter mt10
                        style={{ color: '#060000', fontWeight: '600', fontSize: '20px' }}
                    >
                        Uploading your file...
                    </Box>
                    <Box w="100%" flex toCenter
                        style={{ color: 'What is your next plan?', fontWeight: '18px' }}
                    >
                        Do not close this window until your upload is complete.
                    </Box>

                    <Box flex toCenter w="100%">
                        <Box
                            flex
                            toCenter
                            onClick={() => global.location.reload()}
                            style={{ background: '#518DD9', width: '105px', height: '33px', color: '#FFFFFF', fontSize: '14px', cursor: 'pointer' }}
                        >Cancel</Box>

                    </Box>
                </Box>
            </DialogContent>
        </BootstrapDialog >
    )
}

export default UploadDialog