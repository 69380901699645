import { FC } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from "@fower/react";
import { styled } from '@mui/material/styles';
import { UploadAction } from "../../UploaderModal";

interface ConfirmDialogProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    onAction: (action: UploadAction) => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        // padding: '200px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    open, handleClose, handleConfirm, onAction
}) => {
    return (

        <BootstrapDialog

            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={handleClose}
        >

            <DialogContent style={{ width: '600px', height: '200px', borderRadius: '17px' }}>
                <Box
                    w="100%"
                    h="100%"
                    flex
                    column
                    justifyContent="space-around"
                >
                    <Box w="100%" flex toCenter
                        style={{ color: '#060000', fontWeight: '600', fontSize: '20px' }}
                    >
                        Product created.
                    </Box>
                    <Box w="100%" flex toCenter
                        style={{ color: 'What is your next plan?', fontWeight: '18px' }}
                    >
                        What is your next plan?
                    </Box>

                    <Box flex toCenter w="100%">
                        <Box
                            flex
                            onClick={() => {
                                onAction('buy')
                            }}
                            toCenter
                            style={{ background: '#518DD9', width: '105px', height: '33px', color: '#FFFFFF', fontSize: '14px', cursor: 'pointer' }}
                        >Buy lt</Box>
                        <Box
                            flex
                            onClick={() => {
                                onAction('sell')
                            }}
                            toCenter
                            style={{ background: '#518DD9', width: '105px', height: '33px', color: '#FFFFFF', fontSize: '14px', marginLeft: '20px', cursor: 'pointer' }}

                        >Sell it</Box>
                        <Box
                            flex
                            toCenter
                            onClick={() => {
                                onAction('dashboard')
                            }}
                            style={{ background: '#518DD9', width: '105px', height: '33px', color: '#FFFFFF', fontSize: '14px', marginLeft: '20px', cursor: 'pointer' }}
                        >To Dashboard</Box>
                    </Box>
                </Box>
            </DialogContent>
        </BootstrapDialog>

    )
}

export default ConfirmDialog;