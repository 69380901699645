import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { useDashbardStore } from '../store/useDashboardStore'
import PaymentItem from './PaymentItem'
import { observer } from 'mobx-react-lite'
import AddPaymentInfo from './AddPaymentInfo'
import { useAuthStore } from '../../../store/useAuthStore'

const PaymentInfoList: FC = observer(() => {
  const dashboardStore = useDashbardStore()
  const authStore = useAuthStore();
  const [showAddNewCard, setShowAddNewCard] = useState<boolean>(false)
  const reloadCards = useCallback(() => {
    dashboardStore.getPaymentCards(authStore.token!).then(null)
  }, [authStore.token, dashboardStore])
  useEffect(() => {
    reloadCards()
  }, [dashboardStore, reloadCards])
  return (
    <Box w-100p row spaceX3>
      {dashboardStore.paymentsCards.map((card) => {
        return (
          <PaymentItem
            key={card.id}
            data={card}
            onAddNewCard={() => {
              setShowAddNewCard(true)
            }}
            onDelete={(item) => {
              dashboardStore.deleteStripeCard(item.id).then(() => {
                reloadCards()
              })
            }}
          />
        )
      })}
      <AddPaymentInfo
        isShow={showAddNewCard}
        onComplete={(updated) => {
          if(updated){
            reloadCards()
          }
          setShowAddNewCard(false)
        }}
      />
    </Box>
  )
})
export default PaymentInfoList
