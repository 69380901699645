import { Box } from "@fower/react";
import { Button, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ProductItemVO } from "../../../TypeDeclare";
import { useProductStore } from "../../product_list/store/useProdustListStore";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./styles.css"
import { useNavigate } from "react-router-dom";
import ShowColorPalette from "./ShowColorPalette";

const DetailPane: FC = () => {
    const productStore = useProductStore()
    const navigator = useNavigate()
    const data = undefined;
    const [item, setItem] = useState<(ProductItemVO & { psd_sku?: Array<string> }) | undefined>(data)
    const carouselRef = useRef<AliceCarousel>(null);
    const updateImages = useCallback(() => {
        const arr = productStore.product!.coverImages.map((item, index) => {
            return (
                <Box textCenter key={index}>
                    <img
                        draggable={false}
                        src={item}
                        alt={`${productStore.product!.name}-${index}`}
                        style={{ width: '478px', height: '100%' }}
                    />
                </Box>
            )
        })
        return arr
    }, [productStore.product])
    const [items, setItems] = useState<any[]>(updateImages())
    useEffect(() => {
        const aRunner = async () => {
            await productStore.fillProductImages(productStore.product!.id)
            setItem(productStore.product)
            const items = updateImages()
            setItems(items)
        }
        aRunner()
    }, [productStore])

    // 监听窗口大小变化
    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {

                carouselRef.current._handleResized();  // 调用 refresh 方法来重新调整 Carousel
            }
        };

        // 绑定 resize 事件
        window.addEventListener('resize', handleResize);

        // 清除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderPrevButton = () => (
        <ChevronLeftIcon style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', fontSize: '40px', cursor: 'pointer' }} />

    );

    const renderNextButton = () => (
        <ChevronRightIcon style={{ position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)', fontSize: '40px', cursor: 'pointer' }} />

    );

    if (item === undefined) return <></>
    return (
        <>
            <Box w="80%" style={{ marginTop: '20px' }} className="responsive-box">
                <Box w={['100%', '100%', '100%', '38%', '38%']}>
                    <Typography component="h1" color="#1F5BA7" fontWeight={"bold"} style={{ fontSize: '24px' }}>
                        {item.name}
                    </Typography>

                    <Box w="100%" h="180px" flex justifyContent="space-between" style={{ marginTop: '15px' }}>
                        <Box w="49%" h="100%" flex column justifyContent="center" style={{ background: '#F8F8F8', borderRadius: '4px', paddingLeft: '22px' }}>
                            <span style={{ fontSize: '20px', color: '#030A1E' }}>Price</span>
                            <Typography color="#000000" fontWeight={'bold'} style={{ fontSize: '30px', marginTop: '17px' }}>
                                ${item.pairPrize}
                            </Typography>

                        </Box>
                        <Box w="49%" h="100%" flex column justifyContent="center" style={{ background: '#F8F8F8', borderRadius: '4px', paddingLeft: '22px' }}>
                            <Box flex alignItems="center">
                                <img src="/icons/icon-rule.png" alt="rule" style={{ width: '30px', height: '30px' }} />
                                <span style={{ fontSize: '14px', marginLeft: '8px' }}>{`${item.sizeRange}`}</span>
                            </Box>
                            <Box flex alignItems="center" style={{ marginTop: '17px' }}>
                                <img src="/icons/icon-car.png" alt="car" style={{ width: '30px', height: '23px' }} />
                                <span style={{ fontSize: '14px', marginLeft: '8px' }}>Fast shipping</span>
                            </Box>
                            <Box flex alignItems="center" style={{ marginTop: '20px' }}>
                                <img src="/icons/icon-time.png" alt="time" style={{ width: '28px', height: '33px' }} />
                                <span style={{ fontSize: '14px', marginLeft: '8px' }}>{item.daysInProduction} Days Turnaround to America
                                    <img src="/icons/icon-America.png" alt="America" style={{ width: '25px', height: '15px', marginLeft: '5px' }} />
                                </span>
                            </Box>
                        </Box>
                    </Box>



                    <Typography color="#373737" fontWeight={'bold'} style={{ fontSize: '20px', marginTop: '30px' }}>
                        Color
                    </Typography>
                    <ShowColorPalette
                        colorOptions={productStore.colorOptions}
                    />

                    <Typography color="#373737" fontWeight={'bold'} style={{ fontSize: '20px', marginTop: '30px' }}>
                        Size
                    </Typography>

                    {item.sizeTableImage && (
                        <img
                            src={item.sizeTableImage}
                            alt="sizetable"
                            style={{ width: '100%', marginTop: '20px' }}
                        />
                    )}

                    {/* <img
                        src="/images/size.png"
                        alt="size"
                        style={{ width: '100%', marginTop: item.sizeTableImage ? '20px' : '0' }}
                    /> */}

                </Box>

                <Box w={['0', '0', '0', '2%', '2%']}></Box>

                <Box w={['100%', '100%', '100%', '60%', '60%']} flex column h="610px">
                    <Box w="100%" flex>
                        <Box w={['75%', '75%', '75%', '75%', '75%']}>
                            <AliceCarousel
                                infinite
                                mouseTracking
                                items={items}
                                ref={carouselRef}  // 通过 ref 获取 AliceCarousel 实例
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                            />
                        </Box>

                        <Box w={['25%', '25%', '25%', '25%', '25%']} style={{
                            overflowY: 'scroll', height: '478px', scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}>
                            {
                                productStore.product!.coverImages.map(img => {
                                    return (
                                        <Box
                                            className="imgList"
                                            style={{ width: '100%' }}
                                            key={img}
                                        >
                                            <img
                                                draggable={false}
                                                src={img}
                                                alt={`${productStore.product!.name}-image`}
                                                style={{ objectFit: 'contain', width: '100%', height: '100%' }}


                                            />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>

                    <Box w="75%" flex toCenter>
                        <Button style={{ background: '#4384D6', fontSize: '20px', cursor: 'pointer', width: '60%', borderRadius: '36px' }} variant="contained" onClick={() => {
                            if (item.psd_sku && item.psd_sku.length > 0) {
                                navigator(`/editor?productId=${item.id}`)
                            } else {
                                navigator(`/oldEditor?productId=${item.id}`)
                            }

                        }}>Start Designing</Button>
                    </Box>

                </Box>


            </Box >

            <Box w="80%" mt8>
                <Typography className="description" color="#373737" style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: item.description_html! }}>
                </Typography>
            </Box>
        </>
    )
}

export default DetailPane;