import { Box } from '@fower/react';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import BaseInfoForm from './components/BaseInfoForm';
import SNCMatrix from './components/SNCMatrix';

type SizeNColorProps = {
  onNext: () => void;
  onBack: () => void;
};

const SizeNColor: FC<SizeNColorProps> = observer(({ onNext, onBack }) => {
  return (
    <Box
      w='100%'
      h='100%'
      row
      mt2
      style={{
        background: 'url("/images/connect-bg.png") repeat',
      }}
      toBetween
    >
      <SNCMatrix />
      <Box w='30%' h='100%' bgWhite overflowYAuto>
        <BaseInfoForm onNext={onNext} onBack={onBack} />
      </Box>
    </Box>
  );
});

export default SizeNColor;
