import { FC } from "react";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { styled } from '@mui/system';
import { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IButtonMenu, IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { DomEditor, Boot } from '@wangeditor/editor'
import { useOriginalProductStore } from "../../store/useOriginalProductStore";
import { i18nChangeLanguage } from '@wangeditor/editor';
import { Button, Modal, TextareaAutosize } from "@mui/material";

interface QuillEditorProps {
    onGetHtml: (data: string) => void
    html: string
}

class CodeButtonMenu implements IButtonMenu {
    title: string;
    tag: string;
    constructor() {
        this.title = 'Edit code'
        this.tag = 'button'
    }
    iconSvg?: string | undefined;
    hotkey?: string | undefined;
    alwaysEnable?: boolean | undefined;
    width?: number | undefined;
    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor: IDomEditor): string | boolean {   // TS 语法
        // getValue(editor) {                              // JS 语法
        return false
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor: IDomEditor): boolean {  // TS 语法
        // isActive(editor) {                    // JS 语法
        return false
    }
    isDisabled(editor: IDomEditor): boolean {   // TS 语法
        // isDisabled(editor) {                     // JS 语法
        return false
    }

    // 点击菜单时触发的函数
    exec(editor: IDomEditor, value: string | boolean) {   // TS 语法
        // exec(editor, value) {                              // JS 语法
        if (this.isDisabled(editor)) return
        // @ts-ignore
        window.showCustomModal()
    }
}

const menu1Conf = {
    key: 'menu1', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new CodeButtonMenu() // 把 `YourMenuClass` 替换为你菜单的 class
    },
}

Boot.registerMenu(menu1Conf)


const QuillEditor: FC<QuillEditorProps> = ({ onGetHtml, html }) => {


    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const Textarea = styled(TextareaAutosize)(
        ({ theme }) => `
        box-sizing: border-box;
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

        &:hover {
          border-color: ${blue[400]};
        }

        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }

        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
    );

    const opStore = useOriginalProductStore()
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)   // TS 语法

    const [open, setOpen] = useState(false)
    const [code, setCode] = useState('')

    // 更改语言为英文
    i18nChangeLanguage('en'); // 使用英文，可以替换为其他支持的语言

    async function uploadImages(
        files: Array<File | undefined>,
    ) {
        const result = await opStore.uploadImages(
            files as File[],
            (_) => { },
        )
        return result;
    }

    function handleSave() { }
    // @ts-ignore
    window.showCustomModal = () => {
        setOpen(true)
    }


    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        excludeKeys: ['uploadVideo'],


    }  // TS 语法

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {    // TS 语法
        // const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {
            // 自定义图片上传
            uploadImage: {
                // 自定义上传
                async customUpload(file: File, insertFn: any) {  // TS 语法
                    const res = await uploadImages([file])

                    const imgUrl = res[0].url; // 获取服务器返回的图片 URL
                    insertFn(imgUrl); // 使用 insertFn 方法插入图片
                },
            },
        },
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {

        const toolbar = DomEditor.getToolbar(editor!)
        if (toolbar) {
            const curToolbarConfig = toolbar!.getConfig()

            curToolbarConfig.insertKeys = {
                index: 0,
                keys: ['menu1']
            }
        }

        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100, width: '100%', minHeight: '200px' }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        onGetHtml(editor.getHtml())
                    }}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>

            <Modal
                open={open} onClose={() => setOpen(false)}>
                <div style={{ padding: '20px', backgroundColor: 'white', width: '800px', margin: '100px auto' }}>
                    <h2>Edit Code</h2>

                    <Textarea
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        minRows={20}
                        aria-label="empty textarea" placeholder="Empty" />
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                </div>
            </Modal>

        </>
    )
}

export default QuillEditor;