import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { Divider, Drawer } from '@mui/material'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import { useDashbardStore } from '../store/useDashboardStore'
import { useAuthStore } from '../../../store/useAuthStore'
import {
  DBShopifyOrderVO,
  ShopifyFulfillmentDetailVO,
} from '../../../TypeDeclare'
import ShopifyOrderDetailItem from './ShopifyOrderDetailItem'

export type ShopifyOrderDetailModalProps = {
  isOpen: boolean
  onClose: () => void
  shopifyToken?: string
  orderId?: string
  orderData: DBShopifyOrderVO | undefined
}
const ShopifyOrderDetailModal: FC<ShopifyOrderDetailModalProps> = ({
  isOpen,
  onClose,
  shopifyToken,
  orderId,
  orderData,
}) => {
  const dashbardStore = useDashbardStore()
  const authStore = useAuthStore()
  const [details, setDetails] = useState<Array<ShopifyFulfillmentDetailVO>>([])
  const getProductCost = () => {
    const result = details.reduce((prev, currentItem)=>{
      return Number(currentItem.cost_price * currentItem.quantity) + prev
    },0)
    return result
  }
  const getShipping = () => {
    const result = details.reduce((prev, currentItem)=>{
      return Number(currentItem.shipping) + prev
    },0)
    return result
  }
  const getTotalCosts = () => {
    return getProductCost() + getShipping()
  }
  useEffect(() => {
    if (!isOpen) return
    if (shopifyToken && orderId && orderData)
      dashbardStore
        .getShopifyOrderDetail(authStore.token, shopifyToken, orderId)
        .then((list) => {
          setDetails(list)
        })
  }, [authStore.token, dashbardStore, orderId, shopifyToken, isOpen, orderData])

  if (!shopifyToken || !orderId) {
    return <></>
  }
  const doClose = () => {
    setDetails([])
    onClose()
  }
  return (
    <Drawer anchor="right" open={isOpen} onClose={doClose}>
      <Box w="100vw" w--sm="840px" bgWhite row toTop relative>
        <Box absolute right2 top2 zIndex="1000" cursorPointer onClick={doClose}>
          <CloseTwoToneIcon fontSize="large" />
        </Box>
        <Box column w-100p pr2 pl2 pt12 spaceY2>
          {details.map((detail) => {
            return (
              <ShopifyOrderDetailItem
                data={detail}
                key={detail.product_sku}
                order={orderData!}
              />
            )
          })}
        </Box>
        <Box w--sm="500px" pt12>
          <Box bgGray300 column spaceY2 mr2 p3 rounded bg="#6E8FA0">
            <Box white>Billing</Box>
            <Divider sx={{ borderBlockColor: 'white' }} />
            <Box white textSM row toBetween>
              <Box>Product Costs:</Box>
              <Box>${getProductCost()}</Box>
            </Box>
            <Box white textSM row toBetween>
              <Box>Shipping:</Box>
              <Box>${getShipping()}</Box>
            </Box>
            
            <Divider sx={{ borderBlockColor: 'white' }} />
            <Box white textSM row toBetween>
              <Box>Total costs:</Box>
              <Box>${getTotalCosts()}</Box>
            </Box>
            
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
export default ShopifyOrderDetailModal
