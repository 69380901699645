import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useDashbardStore } from '../store/useDashboardStore'
import { useAuthStore } from '../../../store/useAuthStore'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import {
  DBShopifyOrderVO,
  LinkedShop,
  PaymentCardInfoVO,
  StyledPaper,
} from '../../../TypeDeclare'
import LinkedStoreSelect from '../../../common/LinkedStoreSelect'
import ShopifyOrderDetailModal from './ShopifyOrderDetailModal'
import { AlertColor, Button } from '@mui/material'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import PaymentPicker from '../payments/PaymentPicker'
import ProgressIndicator from '../../../common/ProgressIndicator'

export type OrdersProps = {
  onAddNewCard: () => void
}

const ShopifyOrders: FC<OrdersProps> = observer(({ onAddNewCard }) => {
  const dashboardStore = useDashbardStore()
  const authStore = useAuthStore()
  const [selectedShop, setSelectedShop] = useState<LinkedShop | undefined>()
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>()
  const [selectedOrder, setSelectedOrder] = useState<
    DBShopifyOrderVO | undefined
  >()
  const [shopifyOrders, setShopifyOrders] = useState<Array<DBShopifyOrderVO>>(
    [],
  )
  const [showOrderDetail, setShowOrderDetail] = useState<boolean>(false)
  const [showPaymentPicker, setShowPaymentPicker] = useState<boolean>(false)
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('info')

  const refreshProfile = useCallback(async () => {
    const result = await authStore.loadUserProfile()
    dashboardStore.setLinkedShops(result.rawLinkedShops)
    if (dashboardStore.linkedShops.length !== 0) {
      const shopTo = dashboardStore.linkedShops[0]
      setSelectedShop(shopTo)
    }
  }, [authStore, dashboardStore])
  const refreshOrders = useCallback(() => {
    if (selectedShop) {
      dashboardStore
        .getShopifyOrders(authStore.token, selectedShop.ctToken)
        .then((list) => {
          setShopifyOrders(list)
        })
    }
  }, [authStore.token, dashboardStore, selectedShop])
  const submitOrder = useCallback(
    async (order: DBShopifyOrderVO, card: PaymentCardInfoVO) => {
      setShowProgress(true)
      setUpdating(true)
      const result = await dashboardStore.payByStripe(card.customerId, order.id)
      setUpdating(false)
      if (result) {
        setMessage('Order Submitted!')
        setMessageType('success')
      } else {
        setMessage('Error on Submitting Order!')
        setMessageType('error')
      }
      return result
    },
    [dashboardStore],
  )

  const onShowDetail = (item: DBShopifyOrderVO) => {
    console.log('onShowDetail', item)

    setSelectedOrderId(item.id)
    setSelectedOrder(item)
    setShowOrderDetail(true)
  }
  const onSubmitOrder = (item: DBShopifyOrderVO) => {
    setSelectedOrder(item)
    setShowPaymentPicker(true)
  }

  useEffect(() => {
    refreshOrders()
  }, [dashboardStore, authStore.token, selectedShop, refreshOrders])

  useEffect(() => {
    refreshProfile().then(null)
  }, [dashboardStore, refreshProfile])

  const reloadCards = useCallback(() => {
    dashboardStore.getPaymentCards(authStore.token!).then(null)
  }, [dashboardStore, authStore.token])
  useEffect(() => {
    if (authStore.token) {
      reloadCards()
    }
  }, [dashboardStore, reloadCards, authStore.token])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Order ID', width: 130 },
    {
      field: 'updateTime',
      headerName: 'Sent to production',
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        format(params.row.updateTime, 'MM/dd/yy HH:mm:ss'),
    },

    {
      field: 'customer',
      headerName: 'Customer',
      width: 140,
      valueGetter: (params: GridValueGetterParams) => {
        const fullName = `${params.row.destination.first_name || ''} ${
          params.row.destination.last_name || ''
        }`
        return fullName
      },
    },
    { field: 'totalItems', headerName: 'Items', width: 90 },

    {
      field: 'productionCost',
      headerName: 'Total Cost',
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        `$${params.row.productionCost}`,
    },
    {
      field: 'fulfillmentStatus',
      headerName: 'Fulfillment Status',
      width: 140,
    },
    // { field: 'requestStatus', headerName: 'Request Status', width: 120 },
    {
      field: 'customteeStatus',
      headerName: 'CustomTee Status',
      width: 140,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value === 'Unpaid') {
          return (
            <Box p2 rounded red500 fontBold>
              {params.value}
            </Box>
          )
        }
        return (
          <Box p2 rounded borderGreen500>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.row.customteeStatus === 'Unpaid') {
          return (
            <Box row spaceX3 toCenterY toBetween w="100%">
              <Box row spaceX2>
                <Button
                  variant="contained"
                  onClick={() => {
                    onSubmitOrder(params.row as DBShopifyOrderVO)
                  }}
                >
                  Submit Order
                </Button>
              </Box>
            </Box>
          )
        }
        return <Box row spaceX3 toCenterY toBetween w="100%"></Box>
      },
      width: 160,
    },
    {
      field: 'info',
      headerName: 'More',
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Box
            onClick={() => {
              onShowDetail(params.row as DBShopifyOrderVO)
            }}
          >
            <ReadMoreIcon />
          </Box>
        )
      },
      width: 50,
    },
  ]

  return (
    <Box h="90vh" p10 column spaceY2>
      <LinkedStoreSelect
        selectedShop={selectedShop}
        linkedShops={dashboardStore.linkedShops}
        onSelectedNewShop={(targetShop) => {
          setSelectedShop(targetShop)
        }}
      />
      {shopifyOrders.length === 0 && (
        <StyledPaper toCenter elevation={1}>
          <Box h96 toCenter>
            No orders yet
          </Box>
        </StyledPaper>
      )}

      {shopifyOrders.length !== 0 && (
        <DataGrid
          rows={shopifyOrders}
          columns={columns}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          checkboxSelection={false}
          rowsPerPageOptions={[5]}
          pageSize={5}
          rowHeight={100}
          disableSelectionOnClick={false}
        />
      )}
      <ShopifyOrderDetailModal
        isOpen={showOrderDetail}
        onClose={() => {
          setShowOrderDetail(false)
        }}
        shopifyToken={selectedShop?.ctToken}
        orderId={selectedOrderId}
        orderData={selectedOrder}
      />
      <PaymentPicker
        isOpen={showPaymentPicker}
        onClose={async (card) => {
          setShowPaymentPicker(false)
          if (card && selectedOrder) {
            const result = await submitOrder(selectedOrder, card)
            result && refreshOrders()
          }
        }}
        onAddNewCard={() => {
          setShowPaymentPicker(false)
          onAddNewCard()
        }}
        cards={dashboardStore.paymentsCards}
      />
      <ProgressIndicator
        isOpen={showProgress}
        text={'Submiting Order'}
        loading={updating}
        message={message}
        messageType={messageType}
        onClose={() => {
          setShowProgress(false)
        }}
      />
    </Box>
  )
})
export default ShopifyOrders
