import { FC } from 'react'
import { Box } from '@fower/react'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import { PaymentCardInfoVO } from '../../../TypeDeclare'
import { Drawer } from '@mui/material'
import PaymentItem from './PaymentItem'
import { observer } from 'mobx-react-lite'

export type PaymentPickerProps = {
  cards: Array<PaymentCardInfoVO>
  isOpen: boolean
  onClose: (selectedCard?: PaymentCardInfoVO) => void
  onAddNewCard: () => void
}
const PaymentPicker: FC<PaymentPickerProps> = observer(
  ({ cards, isOpen, onClose, onAddNewCard }) => {
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => {
          onClose()
        }}
      >
        <Box w="100vw" w--sm="240px" bgWhite column toTop>
          <Box row w="100%" toBetween toCenterY p2>
            <Box textXL>Pay with:</Box>
            <Box
              zIndex="1000"
              cursorPointer
              onClick={() => {
                onClose()
              }}
            >
              <CloseTwoToneIcon fontSize="large" />
            </Box>
          </Box>
          <Box column w-100p pr2 pl2 pt10 toCenterX spaceY3>
            {cards.map((card) => {
              return (
                <PaymentItem
                  key={card.id}
                  data={card}
                  canDelete={false}
                  onSelected={(item) => {
                    onClose(item)
                  }}
                  onAddNewCard={() => {
                    onAddNewCard()
                  }}
                />
              )
            })}
          </Box>
        </Box>
      </Drawer>
    )
  },
)
export default PaymentPicker
