import { FC } from 'react'
import { Box } from '@fower/react'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { PaymentCardInfoVO, StyledImage } from '../../../TypeDeclare'
import { fill2 } from '../../../common/StringUtils'

export type PaymentItemProps = {
  data: PaymentCardInfoVO
  onAddNewCard?: () => void
  onDelete?: (data: PaymentCardInfoVO) => void
  onSelected?: (data: PaymentCardInfoVO) => void
  canDelete?: boolean
}
const PaymentItem: FC<PaymentItemProps> = ({
  data,
  onAddNewCard,
  onDelete,
  onSelected,
  canDelete = true,
}) => {
  if (data.newCard) {
    return (
      <Box
        w48
        h28
        border
        rounded
        column
        toCenter
        p2
        shadow
        onClick={() => {
          onAddNewCard && onAddNewCard()
        }}
        spaceY2
      >
        <AddIcon />
        <Box textXS>Set up a credit/debit card</Box>
      </Box>
    )
  }
  const getCardIcon = () => {
    if (data.cardType === 'Visa') {
      return '/icons/visa.svg'
    }
    if (data.cardType === 'Mastercard') {
      return '/icons/mastercard.svg'
    }
    return '/icons/unknown.svg'
  }

  return (
    <Box
      w48
      h28
      border
      rounded
      column
      toBetween
      p2
      shadow
      onClick={() => {
        onSelected && onSelected(data)
      }}
    >
      <Box column toLeft spaceY2>
        <Box row toBetween w-100p>
          <StyledImage src={getCardIcon()} alt="logo" w8 />
          {canDelete && (
            <Box
              onClick={() => {
                onDelete && onDelete(data)
              }}
            >
              <DeleteIcon />
            </Box>
          )}
        </Box>
        <Box gray500 textSM toCenterY>
          {data.cardNumber}
        </Box>
      </Box>
      <Box row toRight spaceX2>
        <Box gray500 textSM toCenterY>
          {fill2(data.expiryMonth)}/{data.expiryYear}
        </Box>
      </Box>
    </Box>
  )
}
export default PaymentItem
