import React, { FC, useState } from 'react'
import { Box } from '@fower/react'
import { Alert, Button, Snackbar, Tab, Tabs } from '@mui/material'
import InfoInput from './InfoInput'
import ColorPad from './ColorPad'
import SizePad from './SizePad'
import { useOriginalProductStore } from '../../store/useOriginalProductStore'
import { useRequest } from 'ahooks'
import { observer } from 'mobx-react-lite'
import TagPad from './TagPad'
import SizeRangeSelection from './SizeRangeSelection'
import ShippingInfo from './ShippingInfo'

export type BaseInfoFormProps = {
    onNext: () => void
    onBack: () => void
}

const BaseInfoForm: FC<BaseInfoFormProps> = observer(({ onNext, onBack }) => {
    const opStore = useOriginalProductStore()
    const { loading } = useRequest(opStore.getSizesNColors.bind(opStore))
    const [tab, setTab] = useState<number>(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }
    const canGoNext = opStore.checker.sizeNColorError === undefined
    return (
        <Box minH="100%" minW="400px" toCenterX column shadowLarge>
            <Box w="80%" h12 borderBottom-1 gray300 toCenterX>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label="Basic" />
                    <Tab label="Variant" />
                    <Tab label="Shipment" />
                </Tabs>
            </Box>
            <Box w="100%" h="100%" toCenterX column p2>
                {tab === 0 && (
                    <Box px4>
                        <InfoInput
                            previewMode={false}
                            productName={opStore.productName}
                            updateProductName={(productName) =>
                                opStore.setProductName(productName)
                            }
                            categories={`${opStore.selectedParentCategory
                                ? opStore.selectedParentCategory?.name
                                : ''
                                } ${opStore.selectedChildCategory
                                    ? opStore.selectedChildCategory?.name
                                    : ''
                                }`}
                            pairPrice={opStore.pairPrice}
                            updatePairPrice={(pairPrice) => opStore.setPairPrice(pairPrice)}
                            designYourOwn={opStore.designYourOwn}
                            updateDesignYourOwn={(designYourOwn) =>
                                opStore.setDesignYourOwn(designYourOwn)
                            }
                            fullPrint={opStore.fullPrint}
                            updateFullPrint={(fullPrint) => opStore.setFullPrint(fullPrint)}
                            printOnDemand={opStore.printOnDemand}
                            updatePrintOnDemand={(printOnDemand) =>
                                opStore.setPrintOnDemand(printOnDemand)
                            }
                            shipsFrom={opStore.shipsFrom}
                            updateShipsFrom={(shipsFrom) => opStore.setShipsFrom(shipsFrom)}
                            description={opStore.description}
                            updateDescription={(description) =>
                                opStore.setDescription(description)
                            }
                            descriptionHtml={opStore.descriptionHtml}
                            updateDescriptionHtml={(descriptionHtml) =>
                                opStore.setDescriptionHtml(descriptionHtml)
                            }
                            shippingMethodsOptions={opStore.shippingMethodsOptions}
                            onShippingMethodsOptionUpdate={opStore.updateShippingMethodsOptionsSelection.bind(
                                opStore,
                            )}
                        />
                    </Box>
                )}

                {tab === 1 && (
                    <Box h-100p w-100p>
                        <ColorPad
                            colorOptions={opStore.colorOptions}
                            loading={loading}
                            selectedColors={opStore.colors}
                            onColorsUpdated={(colors) => {
                                opStore.setColors(colors)
                            }}
                            onRemoveColor={(color) => {
                                opStore.setColors(
                                    opStore.colors.filter((c) => c.id !== color.id),
                                )
                            }}
                        ></ColorPad>
                        <SizePad
                            sizeOptions={opStore.sizeOptions}
                            loading={loading}
                            selectedSizes={opStore.sizes}
                            onSizesUpdated={(sizes) => {
                                opStore.setSizes(sizes)
                            }}
                            onRemoveSize={(size) => {
                                opStore.setSizes(opStore.sizes.filter((s) => s.id !== size.id))
                            }}
                        ></SizePad>
                        <TagPad
                            tagOptions={opStore.tagOptions}
                            loading={loading}
                            selectedTags={opStore.tags}
                            onTagsUpdated={(tags) => {
                                opStore.setTags(tags)
                            }}
                        />
                        <SizeRangeSelection
                            options={opStore.sizes}
                            onUpdate={(newRange) => {
                                opStore.setSizeRange(newRange)
                            }}
                        />
                    </Box>
                )}
                {tab === 2 && (
                    <ShippingInfo
                        weight={opStore.weight}
                        volume={opStore.volume}
                        onWeightChange={(value) => {
                            opStore.setWeight(value)
                        }}
                        onVolumeChange={(value, field) => {
                            opStore.setVolume(value, field)
                        }}
                        previewMode={false}
                    />
                )}
            </Box>
            <Box row w="100%" toEvenly>
                <Button
                    variant="outlined"
                    size="large"
                    sx={{ width: '45%', height: 45, mt: 2, mb: 10 }}
                    onClick={onBack}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    sx={{ width: '45%', height: 45, mt: 2, mb: 10 }}
                    onClick={onNext}
                    disabled={!canGoNext}
                >
                    Next
                </Button>
            </Box>
            {!canGoNext && (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={true}
                >
                    <Alert severity="error">
                        {opStore.checker.sizeNColorError?.reason}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    )
})
export default BaseInfoForm
