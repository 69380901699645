import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
    MyProductItemVO,
    ProductType,
    ShopifyActionType,
} from '../../../TypeDeclare'
import { styled } from '@fower/styled'
// import StraightenIcon from '@mui/icons-material/Straighten'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { loadUserProfile } from '../../../store/authService'
// import { toJS } from 'mobx'

export type DBProductItemProps = {
    item: MyProductItemVO
    productType: ProductType
    hasBindedShop: boolean
    onBuy: (item: MyProductItemVO) => void
    onEdit: (item: MyProductItemVO) => void
    onOutOfStore: (item: MyProductItemVO) => void
    onShopifyAction: (item: MyProductItemVO, action: ShopifyActionType) => void
    onView: (item: MyProductItemVO) => void
}
const StyledImage = styled('img')
const DBProductItem: FC<DBProductItemProps> = ({
    item,
    productType,
    onBuy,
    onEdit,
    onOutOfStore,
    onShopifyAction,
    onView
}) => {


    const { name, images, descShorten, defaultPrice, costPrice, pairPrize, price } = item

    const [showCover, setShowCover] = useState<boolean>(false)

    // useEffect(() => {
    //     loadUserProfile(localStorage.getItem("ctToken") as string).then(res => {
    //         console.log(res)
    //     })
    // }, [])

    const getButtonGroup = () => {
        if (productType === 'customtee') {
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {

                            onView(item)
                        }}
                    >
                        VIEW
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onEdit(item)
                        }}
                    >
                        EDIT
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onBuy(item)
                        }}
                    >
                        BUY
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            onOutOfStore(item)
                        }}
                        color="warning"
                    >
                        DELETE
                    </Button>
                </>
            )
        }
        if (productType === 'shopified') {
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onShopifyAction(item, 'view')
                        }}
                    >
                        VIEW
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onShopifyAction(item, 'remove')
                        }}
                        color="warning"
                    >
                        DELETE
                    </Button>
                </>
            )
        }
        return (
            <>
                <Button
                    variant="contained"
                    onClick={() => {
                        onBuy(item)
                    }}
                >
                    BUY
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onEdit(item)
                    }}
                >
                    {item.onSaleCustomtee && item.onSaleShopify ? 'EDIT' : 'SELL'}
                </Button>
            </>
        )
    }
    return (
        <Box
            minW="280px"
            border="solid 1px rgba(0,0,0,0.2)"
            column
            overflowHidden
            bgWhite
            relative
            cursorPointer
            onMouseEnter={() => {
                setShowCover(true)
            }}
            onMouseLeave={() => {
                setShowCover(false)
            }}
        >
            <StyledImage src={images[0].url} alt={name} />
            <Typography
                variant="h4"
                sx={{
                    m: 2,
                }}
            >
                {name}
            </Typography>
            <Box
                textXS
                maxH20
                ml4
                mr4
                mb2
                gray600
                h12
                style={{
                    wordWrap: 'break-word',
                    lineHeight: '24px',
                    overflowY: 'auto',
                }}
            >
                {descShorten}
            </Box>
            {productType === 'shopified' ? (
                <>
                    <Box m4 color="#527282" fontBold>
                        Retail Price: ${price}
                    </Box>
                    <Box m4 color="#527282" fontBold>
                        Production costs: ${pairPrize}
                    </Box>
                </>
            ) : (
                <>
                    <Box m4 color="#527282" fontBold>
                        Retail Price: ${defaultPrice}
                    </Box>
                    <Box m4 color="#527282" fontBold>
                        Production costs: ${costPrice}
                    </Box>
                </>
            )}

            {showCover && (
                <Box
                    absolute
                    top0
                    left0
                    bottom0
                    right0
                    bg="rgba(0,0,0,0.5)"
                    column
                    toCenter
                    spaceY5
                >
                    {getButtonGroup()}
                    <Box
                        absolute
                        bottom0
                        right0
                        left0
                        h16
                        flex
                        row
                        toRight
                        toCenterY
                        spaceX2
                        p3
                    >
                        {(item.onSaleCustomtee || item.onSaleShopify) && (
                            <Box white>Published to</Box>
                        )}
                        {item.onSaleCustomtee && (
                            <StyledImage src="/icons/logo_macaw_light.png" w8 h8 />
                        )}
                        {item.onSaleShopify && (
                            <StyledImage src="/icons/shopify_glyph.svg" w8 h8 />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}
export default DBProductItem
