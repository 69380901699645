import {FC, useState} from 'react';
import { Box } from '@fower/react';
import { Tab, Tabs } from '@mui/material';
import PaymentInfoList from './PaymentInfoList';
  
const Payments:FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  const handleTabChange = (_event:React.SyntheticEvent, value: number)=>{
    setCurrentTabIndex(value)
  }
  return (
    <Box w-100p h-100vh p10 column toCenterX spaceY10>
      <Box w-100p toRight>
        <Tabs value={currentTabIndex} onChange={handleTabChange}>
          <Tab label="Payment methods" />
          {/* <Tab label="Transactions" /> */}
        </Tabs>
      </Box>
     {
        currentTabIndex === 0 && <PaymentInfoList/>
     } 
    </Box>
  );
};
export default Payments