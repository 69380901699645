import { Box } from "@fower/react";
import { useEffect, useRef, useState } from "react";
import { FooterElement, FooterElements, ProductItemVO, StyledImage } from "../../TypeDeclare";
import { useAuthStore } from "../../store/useAuthStore";
import { useDashbardStore } from "../dashboard/store/useDashboardStore";
import { observer } from "mobx-react-lite";
import InstagramIcon from '@mui/icons-material/Instagram'
import HomeFooter from "../home/Footer";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { doGet } from "../../common/commonService";
import { loadUserProfile } from "../../store/authService";
import { Grid, ImageList, ImageListItem, LinearProgress, Skeleton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import ProItemList from "./ProItemList";

const Betterstyle = observer(() => {

    const navigate = useNavigate()
    const shopName = useParams().shop

    const getCV = () => {
        if (global.innerWidth > 1075) return 3
        if (global.innerWidth > 640) return 2
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())

    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    const authStore = useAuthStore()
    const dashboardStore = useDashbardStore()

    const shop = useRef('')
    const filePicker = useRef(null)

    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState(0);
    const [list, setList] = useState<{
        category_name: string,
        cover_image: string,
        default_price: number,
        name: string,
        product_id: string,
        short_description: string,
    }[]>([
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
    ])

    function loadingData() {
        setList([
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
            {
                category_name: '',
                cover_image: '',
                default_price: 0,
                name: '',
                product_id: '',
                short_description: '',
            },
        ])
    }

    const [tabList, setTabList] = useState<{
        id: number,
        name: string
    }[]>([])
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {

        setValue(newValue);
        loadingData()
        doGet(`https://api.customtee.co/api/customteeShopView/`, { shop: shopName }, authStore.token).then(res => {

            const { main_products } = res.data
            const newList = main_products.filter((item: { category_name: string; }) => item.category_name === tabList[newValue].name)
            setList(newList)
            setLoading(false)
        })
    };

    useEffect(() => {
        authStore.loadUserProfile().then(result => {
            dashboardStore.setLinkedShops(result.rawLinkedShops)
        })
    }, [authStore, dashboardStore])

    useEffect(() => {
        // loadUserProfile(authStore.token).then(result => {
        // shop.current = result.profile_path
        doGet(`https://api.customtee.co/api/customteeShopView/`, { shop: shopName }, authStore.token).then(res => {

            const { categories, main_products } = res.data
            setTabList(categories)
            const newList = main_products.filter((item: { category_name: string; }) => item.category_name === categories[0].name)
            setList(newList)
            setLoading(false)
        })
        // })

    }, [shopName])

    const itemsInGroup = (group: string): FooterElement[] => {
        return FooterElements.filter((e) => {
            return e.group === group
        })
    }

    return (
        <Box w-100p column toCenterX spaceY10 relative>
            {loading && <Box w="100%">
                <LinearProgress />
            </Box>}
            <Box
                absolute
                top0
                left0
                right0
                h={'300px'}
                bg={'#4384D6'}
                zIndex={0}
            ></Box>
            <StyledImage
                src={authStore.profile?.profileImage}
                bgWhite
                border="rgba(10,10,10,0.5) 1px solid"
                circle24
                style={{
                    objectFit: 'contain',
                    marginTop: '80px'
                }}
                zIndex={1}
                onClick={() => {
                    if (filePicker && filePicker.current) {
                        const element = filePicker.current as HTMLInputElement
                        element.click()
                    }
                }}
            />

            <InstagramIcon
                onClick={() => {
                    const res = itemsInGroup('FOLLOW ME')
                    global.location.href = res[0].data
                }}
                style={{
                    color: '#fff',
                    zIndex: '99',
                    width: '28px',
                    height: '28px',
                    cursor: 'pointer',
                }}
            />

            <Box
                maxW="700px"
                mt14>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {
                        tabList.map(item => {
                            return (
                                <Tab label={item.name} key={item.id} />
                            )
                        })
                    }

                </Tabs>
            </Box>
            {/* todo */}

            <ImageList
                sx={{
                    width: '80%',
                    height: '100%',
                    marginTop: '0px',
                    padding: '5px',
                }}
                variant="quilted"
                cols={columns}
                rowHeight={500}
            >
                {list.map((item, index) => {
                    if (!item.product_id) {
                        return (
                            <ImageListItem key={index.toString()}>
                                <Grid key={index.toString()} height={480} width={'100%'}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={'100%'}
                                        height={296}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={'100%'}
                                        height={184}
                                        sx={{
                                            bgcolor: 'grey.200',
                                        }}
                                    />
                                </Grid>
                            </ImageListItem>
                        )
                    }

                    return (
                        <ImageListItem key={item.product_id}>
                            <Box
                                minW="280px"
                                h="480px"
                                column
                                overflowHidden

                                relative
                                cursorPointer
                                onClick={() => {
                                    const name = item.name.split(' ').join('-').toLowerCase()
                                    setLoading(true)
                                    navigate(`/shop/${shopName}/${name}-${item.product_id}`)

                                }}
                            >
                                <StyledImage
                                    src={item.cover_image}
                                    alt={item.name}
                                    // className={styles.itemImage}
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '296px',
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '280px'
                                    }}
                                    sx={{
                                        m: 1,
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                <Box
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: '#636363',
                                        display: '-webkit-box',
                                        'WebkitLineClamp': '3',
                                        'WebkitBoxOrient': 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'normal',
                                    }}
                                    textXS ml2 mr4 mb2 h12 >{item.short_description}
                                </Box>

                                <Box ml2 mt2 color="#518DD9" style={{
                                    fontFamily: 'Segoe UI, Segoe UI',
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }}>
                                    ${item.default_price}
                                </Box>

                            </Box>
                        </ImageListItem>
                    )

                })}
            </ImageList>


            <HomeFooter />
        </Box >
    )
})
export default Betterstyle;