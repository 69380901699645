import { FC } from 'react'
import { MenuItem, Select } from '@mui/material'
import { LinkedShop } from '../TypeDeclare'

export type LinkedStoreSelectProps = {
  selectedShop?: LinkedShop
  linkedShops: Array<LinkedShop>
  onSelectedNewShop: (newShop: LinkedShop) => void
}
const LinkedStoreSelect: FC<LinkedStoreSelectProps> = ({
  selectedShop,
  linkedShops,
  onSelectedNewShop,
}) => {
  if(!selectedShop) return <></>
  return (
    <Select
      value={selectedShop?.url}
      label="Shops"
      onChange={(event) => {
        const newURL = event.target.value
        const targetShop = linkedShops.find((s) => s.url === newURL)
        if (targetShop) {
          onSelectedNewShop(targetShop)
        }
      }}
    >
      {linkedShops.map((shop) => {
        return (
          <MenuItem key={shop.url} value={shop.url}>
            {shop.url}
          </MenuItem>
        )
      })}
    </Select>
  )
}
export default LinkedStoreSelect
