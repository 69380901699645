import { Card } from '@stripe/stripe-js';
import { doDelete, doGet, doPost } from '../../../common/commonService';
import {
    PaymentCardInfoVO,
    ProductType,
    ShopifyActionType,
} from '../../../TypeDeclare';

export const getOrders = (token: string): Promise<any> => {
    const url = 'https://api.customtee.co/api/mycustomteeshopifyorders/';
    return doGet(url, {}, token);
};

export const getShopifyOrders = (
    token: string,
    shopToken: string
): Promise<any> => {
    const url = `https://api.customtee.co/api/shopifyStoreOrders/`;
    return doGet(url, { token: shopToken }, token);
};

export const getShopifyOrderDetail = (
    token: string,
    shopToken: string,
    orderId: string
): Promise<any> => {
    const url = `https://api.customtee.co/api/shopifyStoreOrderDetails/${orderId}`;
    return doGet(url, { token: shopToken }, token);
};

export const getProducts = (
    token: string,
    type: ProductType,
    page: number = 1
): Promise<any> => {
    let params: any = { page };
    if (type === 'customtee') {
        params['onsale'] = 1;
    }
    const url = 'https://api.pairdrop.com/api/mydesignedproductsV2/';
    return doGet(url, params, token);
};
export const getShopifyProducts = (
    token: string,
    shopToken: string,
    page: number
): Promise<any> => {
    const url = `https://api.customtee.co/api/shopifyProducts/`;
    return doGet(url, { token: shopToken, page }, token);
};

export const updateShopifyProduct = (
    token: string,
    shopToken: string,
    prodoctId: string,
    action: ShopifyActionType
): Promise<any> => {
    const url = `https://api.customtee.co/api/shopifyProducts/${prodoctId}/`;
    const formData = new FormData();
    formData.append('token', shopToken);
    if (action === 'add') {
        return doPost(url, formData, token);
    }
    return doDelete(url, formData, token);
};

export const updateProduct = (
    token: string,
    productId: string,
    formData: FormData
): Promise<any> => {
    const url = `https://api.pairdrop.com/api/mydesignedproduct/${productId}/`;
    return doPost(url, formData, token);
};

export const bindPlatformShop = (token: string, key: string): Promise<any> => {
    const url = `https://api.customtee.co/api/shopifyTokenBind`;
    return doGet(url, { key }, token);
};

export const getShopifyLink = async (
    token: string,
    productId: string,
    shopifyToken: string
): Promise<string> => {
    const url = `https://api.customtee.co/api/shopifyProductLink/${productId}/`;
    const result = await doGet(url, { token: shopifyToken }, token);
    if (result.success) {
        return result['shopify_link'];
    }
    return '';
};

export const getStripeCS = async (token: string) => {
    // const url = `http://localhost:3333/`;
    const url = `https://api.customtee.co/api/shopifyStripeClientKey`;
    return doGet(url, {}, token);
};

export const getPaymentInfoList = async (token: string): Promise<
    Array<PaymentCardInfoVO>
> => {
    const url = `https://api.customtee.co/api/userpaymentlist/`;
    const result = await doGet(url, null, token)
    const rawArray: any[] = result.data
    const array: Array<PaymentCardInfoVO> = rawArray.map((d: any) => {
        return {
            id: String(d['id']),
            cardNumber: d['card_number'],
            expiryMonth: d['expire_month'],
            expiryYear: d['expire_year'],
            cardType: d['card_type'],
            customerId: d['customer_id']
        }
    })

    return array;
};

export const runStripePayment = async (
    token: string,
    card?: Card,
    stripeToken?: string,
    orderId?: string,
    customerId?: string,
): Promise<boolean> => {
    const url = `https://api.customtee.co/api/shopifyPaymentProcess`;
    const formData = new FormData();
    if (card) {
        const cardInfo = {
            address_zip: card.address_zip,
            last4: card.last4,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            brand: card.brand
        };
        formData.append('card_info', JSON.stringify(cardInfo));
    }
    if (stripeToken) {
        formData.append('stripe_token', stripeToken);
    }
    if (orderId) {
        formData.append('fulfillment_order_id', orderId);
    }

    if (customerId) {
        formData.append('customer_id', customerId);
    }
    const result = await doPost(url, formData, token);
    console.log(result);
    if (result.success) {
        return true
    }
    return false
};

export const deleteStripeCard = async (
    token: string,
    paymentId: string
): Promise<boolean> => {
    const url = `https://api.customtee.co/api/userpaymentlist/${paymentId}/`;
    const result = await doDelete(url, new FormData(), token);
    console.log(result);
    return true
};
