import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";
import { Box } from "@fower/react";
import { styled } from '@mui/material/styles';
import { FC, useCallback, useState } from "react";

interface UploadDialogProps {
    open: boolean
    handleClose: () => void
    handleUpload: (file: any) => void
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '1px'
});

const UploadDialog: FC<UploadDialogProps> = ({
    open,
    handleClose,
    handleUpload
}) => {

    const dialogSty = {
        width: '925px',
        height: '351px',
        maxWidth: '1000px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        handleUpload(event.target.files?.[0])

    };

    const [isDragging, setIsDragging] = useState(false);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDragging(false);
        const files = event.dataTransfer.files;
        // Handle files here (upload, etc.)
        console.log('Dropped files:', files);
        handleUpload(files?.[0])
    }, []);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('enter', event.target)
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >

            <DialogTitle style={{
                fontSize: '21px',
                fontWeight: '600',
                color: '#373737'
            }}>File library</DialogTitle>
            <DialogContent style={dialogSty}>

                <Box
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    css={{
                        width: '98%',
                        height: '280px',
                        border: `1px dashed ${isDragging ? '#81c784' : '#ccc'}`,  // Highligh when dragging
                        backgroundColor: isDragging ? '#f1f8e9' : '#fff',  // Change background color when dragging
                        transition: 'background-color 0.3s, border-color 0.3s'  // Smooth transition for visual effects
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                >
                    <img src="/icons/icon-upload.png" alt="Upload Icon" />
                    <span style={{ textAlign: 'center', marginTop: '20px', lineHeight: '20px' }}>
                        Drag your files here<br />
                        Submit files in PNG or JPEG format.
                    </span>
                    <Button
                        component="label"
                        variant="contained"
                        style={{
                            width: '195px',
                            height: '33px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                            background: 'rgb(81,141,217)',
                            fontWeight: 'bold',
                            marginTop: '20px'
                        }}
                    >
                        Upload
                        <VisuallyHiddenInput accept="image/*" type="file" onChange={handleFileChange} />
                    </Button>
                </Box>

            </DialogContent>

        </Dialog>
    );
}

export default UploadDialog;
