import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import CopyBar from '../../common/CopyBar'
import { OrderInStoreVO } from '../../TypeDeclare'
import { Button } from '@mui/material'

const OrderItem: FC<{
  data: OrderInStoreVO
  onSelected: (data: OrderInStoreVO) => void
}> = observer(({ data, onSelected }) => {
  return (
    <Box
      w="100%"
      rounded
      shadowHuge
      border="solid 1px rgba(0,0,0,0.2)"
      bgBlue100
      p6
      row
      toCenterY
      toBetween
    >
      <Box column>
        <CopyBar label="Order name" text={data.order_name} />
        <CopyBar label="Status" text={data.status_comment} />
        <CopyBar label="Amount Charged" text={data.amount_charged.toString()} />
        <CopyBar
          label="Paid time"
          text={new Date(Date.parse(data.paid_time)).toLocaleString()}
        />
        <CopyBar label="Tracking Link" text={data.trackingLink} isURL={true} />
      </Box>
      <Button variant='contained' onClick={()=>{
        onSelected(data)
      }}>Detail</Button>
    </Box>
  )
})
export default OrderItem
