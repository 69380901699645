import { Dialog } from '@mui/material';
import {FC} from 'react';
import { StyledImage } from '../../TypeDeclare';
  
export type PopupGuideProps = {
  isOpen:boolean
  onClose: ()=>void
}
const PopupGuide:FC<PopupGuideProps> = ({isOpen, onClose}) => {
  
  return (
    <Dialog open={isOpen}>
        <StyledImage src='/images/popup-ad-a.png' onClick={onClose} square-32rem/>
    </Dialog>
  );
};
export default PopupGuide