import { FC } from 'react'
import { Box } from '@fower/react'
import { Link } from 'react-router-dom'

export type NotFoundProps = {
  
}
const NotFound: FC<NotFoundProps> = () => {

  


  return (
    <Box
      w-100p
      h-100vh
      column
      spaceY5
      toCenter
      style={{
        background: 'url("/images/connect-bg.png") repeat',
      }}
    >
      <Box black text4XL>404</Box>
      <Box black text2XL>Oops! You seem to be lost.</Box>
      <Link to='/'>Home</Link>

    </Box>
  )
}
export default NotFound
