import { FC, useState } from 'react'
import { Box } from '@fower/react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

import { Alert, Button, Dialog } from '@mui/material'
import { useDashbardStore } from '../store/useDashboardStore'
import { StyledImage, StyledSnackbar } from '../../../TypeDeclare'

export type AddPaymentInfoProps = {
    isShow: boolean
    onComplete: (updated: boolean) => void
}
const AddPaymentInfo: FC<AddPaymentInfoProps> = ({ isShow, onComplete }) => {
    const dashboardStore = useDashbardStore()
    const stripe = useStripe()
    const elements = useElements()
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [inProgress, setInProgress] = useState<boolean>(false)
    const handleMessageClose = () => {
        setShowMessageBar(false)
    }

    const handleSubmit = async () => {
        if (!stripe || !elements) return
        const card = elements.getElement(CardElement)
        if (card === null) return
        setInProgress(true)
        const { error, token } = await stripe.createToken(card, {})

        if (error) {
            console.log('Error:', error)
            setErrorMessage(error.message!)
            setShowMessageBar(true)
            setInProgress(false)
        } else {
            const result = await dashboardStore.saveStripeToken(token.id, token.card!)
            setInProgress(false)
            if (result) {
                onComplete(true)
            } else {
                setErrorMessage('Can not save card info!')
                setShowMessageBar(true)
            }
        }
    }
    return (
        <Dialog
            open={isShow}
            onClose={() => {
                onComplete(false)
            }}
        >
            <Box w="100%" w--md="600px" column toCenter spaceY10>
                <Box w="100%" text3XL p10 bg="#4384D6" white toRight>
                    Add New Card
                </Box>
                <Box w="100%" h="100%" column p10>
                    <Box w="100%" h="100%" flex column toBetween spaceY16>

                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '20px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#black',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        ></CardElement>
                        <Box toRight toCenterY row>
                            <Box>Secure Payment with</Box>
                            <StyledImage src="/icons/stripe-logo.svg" w24 />

                        </Box>
                        <Box spaceX2 row w="100%">
                            <Button
                                fullWidth
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                    onComplete(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={inProgress}
                            >
                                {inProgress ? 'Adding...' : 'Add'}
                            </Button>
                        </Box>

                    </Box>
                    {/* </StyledForm> */}
                </Box>
            </Box>
            <StyledSnackbar
                autoHideDuration={3000}
                open={showMessageBar}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </StyledSnackbar>
        </Dialog>
    )
}
export default AddPaymentInfo
