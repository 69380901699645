import { Box } from '@fower/react'
import { FC } from 'react'
import { OriginalProductItemVO } from '../TypeDeclare'
import { Divider } from '@mui/material'
import ImagesSelector from './ImagesSelector'

export type RawProductImageReEditProps = {
    isOpen: boolean
    product?: OriginalProductItemVO
    onUpdate: (
        newImages: Array<string | File>,
        type: 'cover' | 'editable',
    ) => void
}
const RawProductImageReEdit: FC<RawProductImageReEditProps> = ({
    product,
    onUpdate,
}) => {
    if (!product) {
        return <></>
    }
    return (
        <Box w="100%" minH="520px" h="100%" column spaceY2 borderGray300 border-1>
            <ImagesSelector
                title="Cover Images"
                images={product.coverImages}
                upTo={8}
                onUpdate={(images) => {
                    onUpdate(images, 'cover')
                }}
            />
            <Box w="96%" pl="2%">
                <Divider />
            </Box>
            <ImagesSelector
                title="Editable Images"
                images={product.images}
                upTo={2}
                onUpdate={(images) => {
                    onUpdate(images, 'editable')
                }}
            />
        </Box>
    )
}
export default RawProductImageReEdit
