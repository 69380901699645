import { Box } from '@fower/react'
import { styled } from '@fower/styled'
import {
  Alert,
  Drawer,
  Pagination,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { useMount } from 'ahooks'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { OrderInStoreVO, OrderType } from '../../TypeDeclare'
import OrderDetail from './OrderDetail'
import OrderItem from './OrderItem'
import { OrderListProvider, useOrderListStore } from './store/useOrderListStore'
import { useAuthStore } from '../../store/useAuthStore'
import ShopifyOrderDetail from './ShopifyOrderDetail'
import { addCTTrackLink } from './services/orderListService'

const OrderListWrapper = observer(() => {
  const store = useOrderListStore()
  const authStore = useAuthStore()
  const StyledPaination = styled(Pagination)
  const [selectedItem, setSelectedItem] = useState<OrderInStoreVO | undefined>(
    undefined,
  )
  const [type, setType] = useState<OrderType>('web')
  const [showMessage, setShowMessage] = useState<boolean>(false)
    const [hasError, setHasError] = useState<boolean>(false)
    const [msg, setMsg] = useState<string>('')
    const handleClose = () => {
      setShowMessage(false)
    }
  const showDetail = Boolean(selectedItem)
  const reloadOrders = (t: OrderType) => {
    store.getOrders(t, 1, authStore.token).then((res) => {
      res.reduce((prev, current) => {
        return prev + current.amount_charged
      }, 0)
    })
  }
  useMount(() => {
    reloadOrders(type)
  })
  if(!authStore.isPM){
    return <Box>Admin Zone</Box>
  }
  return (
    <Box w="100%" w--xl="70%" toCenterX column spaceY2>
      <ToggleButtonGroup
        value={type}
        exclusive
        onChange={(event, value) => {
          setType(value)
          reloadOrders(value)
        }}
        sx={{
          position: 'fixed',
          right: '0px',
        }}
      >
        <ToggleButton value="web" aria-label="left aligned">
          Web
        </ToggleButton>
        <ToggleButton value="app" aria-label="centered">
          App
        </ToggleButton>
        <ToggleButton value="shopify" aria-label="centered">
          Shopify
        </ToggleButton>
      </ToggleButtonGroup>
      <StyledPaination
        fixed
        bottom1
        right1
        bgWhite
        p3
        shadow
        rounded
        count={store.pages}
        page={store.currentPage}
        onChange={(event, value) => {
          store.setPage(value)
          store.getOrders(type, value, authStore.token).then(null)
        }}
      ></StyledPaination>
      {store.orders.map((order) => {
        return (
          <OrderItem
            data={order}
            key={order.order_id}
            onSelected={(item) => {
              setSelectedItem(item)
            }}
          />
        )
      })}
      <Drawer
        anchor="right"
        open={showDetail}
        onClose={() => {
          setSelectedItem(undefined)
        }}
      >
        {selectedItem?.type === 'shopify' ? (
          <ShopifyOrderDetail data={selectedItem} />
        ) : (
          <OrderDetail
            data={selectedItem}
            onAddTrackingLink={(newLink: string) => {
              addCTTrackLink(
                newLink,
                selectedItem?.order_name!,
                type,
                authStore.token!,
              ).then(res => {
                setShowMessage(true);
                if(res['data']['success']){
                  setMsg('Tracking Link Updated!')
                  setHasError(false)
                }else{
                  setMsg('Can not update Tracking Link!')
                  setHasError(true)
                  console.log(res);      
                }
              })
            }}
          />
        )}
      </Drawer>
      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showMessage}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={hasError ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
    </Box>
  )
})
const OrderList = () => {
  return (
    <OrderListProvider>
      <Box w="100%" toCenter>
        <OrderListWrapper></OrderListWrapper>
      </Box>
    </OrderListProvider>
  )
}
export default OrderList
