import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Button, CircularProgress, Popover, Typography } from '@mui/material'
import { useEditorStore } from '../../store/editor/useEditorStore'
import { AddElementActionBar } from '../right/AddElementActionBar'
import { createCustomTeeProduct } from '../../../product_list/services/productService'
import { useProductStore } from '../../../product_list/store/useProdustListStore'
import { useAuthStore } from '../../../../store/useAuthStore'
import { ProductItemVO } from '../../../../TypeDeclare'
export type MobileToolBarProps = {
  product?: ProductItemVO
}
const MobileToolBar: FC<MobileToolBarProps> = observer(({product}) => {
  const store = useEditorStore()
  const authStore = useAuthStore()
  const productStore = useProductStore()
  const [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null)
  const [isAddButtonsOpen, setIsAddButtonsOpen] = useState(false)
  return (
    <Box w="100vw" toBetween={!store.uploading} toCenter={store.uploading} row>
      {store.uploading && (
        <Box row>
          <Typography variant="h6">Uploading</Typography>
          <CircularProgress
            size={24}
            sx={{
              ml: 2,
              color: 'green',
            }}
          />
        </Box>
      )}
      {!store.uploading && (
        <Box
          ml2
          row
          toCenterY
          spaceX2
          onClick={(event: {
            currentTarget: SetStateAction<HTMLElement | null>
          }) => {
            if (store.uploading) return
            setAnchorEL(event.currentTarget)
            setIsAddButtonsOpen(true)
          }}
        >
          <AddCircleIcon fontSize="large" />
          <Box borderRight="1px solid rgba(0,0,0,0.1)" h="80%"></Box>
        </Box>
      )}
      {!store.uploading && (
        <Box row spaceX3 mr3>
          <Button
            variant="outlined"
            sx={{
              borderColor: 'black',
              color: 'black',
              pr: 3,
              pl: 3,
            }}
            onClick={() => {
              store.setShowBottomMenu(true)
            }}
          >
            Editor
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'black',
              pr: 3,
              pl: 3,
            }}
            onClick={async () => {
              if (!authStore.isLogin) {
                authStore.setShowLoginModalOnEditor(true)
                return
              }
              const fullColorImages = store.createFullColorPreviews(
                productStore.colorOptions,
              )
              const images = store.createAssets(fullColorImages.length + 1)
              const allResult = await store.uploadItem([
                ...fullColorImages,
                ...images,
              ])
              const result = allResult.filter(
                (r) => !r.alias.includes('fullcolor'),
              )
              const fullColorResult = allResult
                .filter((r) => r.alias.includes('fullcolor'))
                .map((fc) => {
                  const arr = fc.name.split('-')
                  return {
                    colorId: arr[0],
                    colorName: arr[1],
                    url: fc.url,
                    face: fc.alias.split('_')[0],
                  }
                })
              if (result.length === 0) return
              store.save('uploadedImages', JSON.stringify(result))
              const formData = await createCustomTeeProduct(
                store,
                productStore,
                result,
                fullColorResult,
                authStore.token,
              )
              store.save('sku', formData['product_sku'])
              const sku = formData['data']['product_sku']
              const newProductID = formData['data']['product_id']
              const urlTo = `/checkout?productId=${store.product?.id}&sku=${sku}&quantity=${productStore.quantity}&size=${productStore.size.id}&colorId=${store.templateColor.id}`
              store.purchaseURLAfterUpload = urlTo
              store.setNewCreatedProductId(newProductID)
              store.save('purchase_url', urlTo)
            }}
          >
            Continue
          </Button>
        </Box>
      )}

      <Popover
        open={isAddButtonsOpen}
        onClose={() => {
          setIsAddButtonsOpen(false)
          setAnchorEL(null)
        }}
        anchorEl={anchorEL}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableScrollLock
      >
        <AddElementActionBar
          styles={{}}
          isMobile={true}
          onSelected={(type) => {
            setIsAddButtonsOpen(false)
            setAnchorEL(null)
          }}
          product={product}
        />
      </Popover>
    </Box>
  )
})
export default MobileToolBar
