import { FC, SetStateAction, useState } from "react";
import { Box } from "@fower/react";
import { IconButton, Popover } from "@mui/material";
import { ChromePicker } from "react-color";
import { styled } from "@fower/styled";

interface ColorPickerColumnProps {
    onChangeComplete: (color: string) => void,
    selectedColor: string
}
const ColorPickerColumn: FC<ColorPickerColumnProps> = ({ onChangeComplete, selectedColor }) => {
    const colorList = [
        '#FFFFFF', '#000000', '#D2D2D2'
    ]

    const [active, setActive] = useState(-1)

    const [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null);

    const isColorWheelOpen = Boolean(anchorEL);
    const colorWheelID = isColorWheelOpen ? 'simple-popover' : undefined;
    const StyledImage = styled('img');

    return (
        <Box
            flexDirection="column"
        >
            <img
                src="/icons/icon-transport.png"
                style={{ width: '24px', height: '24px' }}
                onClick={() => {
                    onChangeComplete('transparent')
                }}
            />
            {
                colorList.map(item => {
                    return (
                        <Box
                            onClick={() => {
                                onChangeComplete(item)
                            }}
                            key={item}
                            style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '4px',
                                background: item,
                                border: item === '#FFFFFF' ? '1px solid #000' : 'none',
                                marginTop: '5px'
                            }}
                        ></Box>
                    )
                })
            }
            <>
                <IconButton
                    size='large'
                    aria-label='choose color'
                    component='div'
                    onClick={(event: {
                        currentTarget: SetStateAction<HTMLElement | null>;
                    }) => {
                        setAnchorEL(event.currentTarget);
                        setActive(-1)
                    }}
                    sx={{
                        padding: '0px',
                        margin: '5px 0px 0px 5px',
                        position: 'relative',
                        width: '34px',
                        height: '34px',

                    }}
                    style={{
                        margin: '5px 0 0 0'
                    }}
                    edge='end'
                >
                    <StyledImage
                        aria-describedby={colorWheelID}
                        left0
                        top0
                        absolute
                        w='24px'
                        h='24px'
                        src='/icons/btn_color_select_ui.png'
                        alt='color wheel'

                    />
                </IconButton>

                <Popover
                    id={colorWheelID}
                    anchorEl={anchorEL}
                    open={isColorWheelOpen}
                    onClose={() => {
                        setAnchorEL(null);
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <ChromePicker
                        color={selectedColor}
                        onChange={(color) => {
                            console.log(color)
                            onChangeComplete(color.hex)
                        }}
                    />
                </Popover>
            </>
        </Box>
    )
}

export default ColorPickerColumn